import styled, { createGlobalStyle } from 'styled-components';

const Container = styled.div`
  text-align: center;
  padding: 5rem 0;
  background-color: #1a1a2e;
  position: relative;
  overflow: hidden;
  min-height: 70vh;
`;

const Title = styled.h1`
  font-size: 4rem;
  font-family: 'Inria Serif', serif;
  font-weight: 700;
  margin-bottom: 0.5rem;


  span:first-child {

background: linear-gradient(
    90deg, 
    #fff 24%, 
    #524FEA 30%,
rgb(198, 53, 234) 90%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-family: 'Inria Serif', serif;
    }

  span:last-child {
    color: #ffffff; 
  }
`;

const Subtitle = styled.p`
  color:rgba(216, 212, 212, 0.97); 
  max-width: 60%;
  margin: 1rem auto 3rem;
  font-size: 1.5rem;
  font-weight: 600;

`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 4rem;
`;

const Button = styled.button`
  background-color: ${(props) => (props.primary ? '#524FEA' : '#1f2937')}; 
  color: #ffffff;
  padding: 1.25rem 2rem;
  border-radius: 9999px; 
  transition: background-color 0.3s;
  border: none;
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 600;

  &:hover {
    background-color: ${(props) => (props.primary ? '#524FEA' : '#1f2937')}; 
  }
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 5rem;
`;

const StatItem = styled.div`
  text-align: center;

  .value {
    background: linear-gradient(
    90deg, 
    #2662EB 24%, 
    #524FEA 30%,
rgb(198, 53, 234) 90%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-family: 'Inria Serif', serif;
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
  }

  .label {
    color:rgb(209, 212, 215); 
    font-size: 1rem;
    font-weight: 500;
  }
`;


const GlobalStyle = createGlobalStyle`
  @keyframes floatBlue {
    0% {
      transform: translate(0, 0);
    }
    33% {
      transform: translate(-100px, 80px);
    }
    66% {
      transform: translate(80px, -45px);
    }
    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes floatPurple {
    0% {
      transform: translate(0, 0);
    }
    33% {
      transform: translate(125px, -50px);
    }
    66% {
      transform: translate(-125px, 45px);
    }
    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes floatPink {
    0% {
      transform: translate(0, 0);
    }
    33% {
      transform: translate(-100px, -125px);
    }
    66% {
      transform: translate(120px, 80px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
`;

const GradientOrb = styled.div`
  position: absolute;
  border-radius: 50%;
  filter: blur(80px);
  opacity: 0.5;
  z-index: 1;
`;

const BlueOrb = styled(GradientOrb)`
  width: 300px;
  height: 300px;
  background: radial-gradient(circle at center, #4361EE, transparent);
  top: 0;
  left: 0%;
  animation: floatBlue 15s infinite ease-in-out;

`;

const PurpleOrb = styled(GradientOrb)`
  width: 400px;
  height: 400px;
  background: radial-gradient(circle at center, #7209B7, transparent);
  top: 5%;
  right: 5%;
  animation: floatPurple 18s infinite ease-in-out;

`;

const PinkOrb = styled(GradientOrb)`
  width: 350px;
  height: 350px;
  background: radial-gradient(circle at center, #B5179E, transparent);
  bottom: 5%;
  left: 5%;
  animation: floatPink 20s infinite ease-in-out;

`;

const HeroSection = () => {
  const stats = [
    { value: '50K+', label: 'Active Students' },
    { value: '100+', label: 'Expert Mentors' },
    { value: '95%', label: 'Success Rate' },
    { value: '10K+', label: 'Practice Tests' },
  ];

  return (
    <>
      <GlobalStyle />
      <Container>
        <BlueOrb />
        <PurpleOrb />
        <PinkOrb />
        <>
          <Title>
            <span>Transform Your Exam Preparation</span>
            <br />
            <span>with AI-Powered Learning</span>
          </Title>
          <Subtitle>
            Personalized mock tests, real-time analytics, and adaptive learning paths designed
            for students from Class 6 to 12 and competitive exam aspirants.
          </Subtitle>
          <ButtonGroup>
            <Button primary>Start Free Trial</Button>
            <Button>Watch Demo</Button>
          </ButtonGroup>
          <StatsContainer>
            {stats.map((stat, index) => (
              <StatItem key={index}>
                <div className="value">{stat.value}</div>
                <div className="label">{stat.label}</div>
              </StatItem>
            ))}
          </StatsContainer>
        </>
      </Container>
    </>
  );
};

export default HeroSection;
