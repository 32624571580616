import React, { useEffect, useState, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getMatches } from "../../../../functions/api/employers/match/getResumes";
import { useNavigate, useParams } from "react-router";
import LogoHeader from "../../../commonComponents/LogoHeader";
import styled, { css, keyframes } from "styled-components";
import ModalHOC from "../../SeekerDashboard/ModalHOC";
import ScheduleModal from "./ScheduleModal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import eyeIcon from "../../../../assets/icons/visible.png";
import { useDispatch } from "react-redux";
import { addResumes, addSelectedJd, resetSelection, toggleSelected } from "../../../../slices/invitationSlice";
import { Tooltip } from "react-tooltip";
import {
  Pagination,
  PaginationSizeFilter,
} from "../../../commonComponents/Pagination";
import { getBlobData } from "../../../../functions/api/resume/getBlobData";
import TableSearchBar from "../commonComponents/TableSearchBar";
import EmpSelectInput from "../commonComponents/EmpSelectInput";
import iIcon from '../../../../assets/icons/iIcon.png'
import visibleIcon from '../../../../assets/icons/visible.png'
import { getEmployer } from "../../../../functions/api/employers/profile/getEmployer";
import { getAllTrackers } from '../../../../functions/api/employers/tracker/getAllTrackers'
import { addTrackers } from '../../../../functions/api/employers/tracker/addTrackers'
import { getJdByClientCode } from "../../../../functions/api/employers/getJdByClientCode";
import downloadIcon from '../../../../assets/icons/Profile/download.png'
import { updateTrackers } from "../../../../functions/api/employers/tracker/updateTrackers";
import { deleteTracker } from "../../../../functions/api/interview/deleteTracker";
import CheckInterestSlotModal from "../commonComponents/CheckInterestSlotModal";
import editIcon from "../../../../assets/icons/editBlack.png"
import UpdateMatchedResumeDetails from "../commonComponents/UpdateMatchedResumeDetails";
import ProgressBar from "../../../commonComponents/ProgressBar";

import { ThumbsUp, ThumbsDown, Send } from 'lucide-react';
import { getAllTrackersWithInterviewStatus } from "../../../../functions/api/employers/tracker/getAllTrackersWithInterviewStatus";
import RescheduleSlotSelection from "./RescheduleSlotSelection";
import ConfigurableBackdropModal from "../../SeekerDashboard/seekerCommonComponents/ConfigurableBackdropModal";
import CommonDrawer from "../../../commonComponents/CommonDrawer";
import SeekerInterviewDetails from "../../SeekerDashboard/sidebarPages/SeekerInterviewDetails";
import { timeZoneConversion } from "../../../../utils/timeZoneConversation";
import { getAllProctoringResults } from "../../../../functions/api/interview/getAllProctoringResults";
import {
  FileText,
  Loader2,
  CheckCircle2,
  XCircle
} from 'lucide-react';


const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #f3f4f6;
  margin-bottom: 0.5rem;
`;

const IconText = styled.span`
  font-size: 0.8rem;
  font-weight: 500;
  color: ${props => props.color};
`;

const StyledIcon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  color: ${props => props.color};
`;

const InterestStatusIcon = ({ status }) => {
  return (
    status === "SENT"
      ?
      <IconWrapper>
        <IconCircle>
          <StyledIcon as={Send} color="#ca8a04" />
        </IconCircle>
        <IconText color="#ca8a04">Sent</IconText>
      </IconWrapper>
      :
      status === "INTERESTED"
        ?
        <IconWrapper>
          <IconCircle>
            <StyledIcon as={ThumbsUp} color="#16a34a" />
          </IconCircle>
          <IconText color="#16a34a">Interested</IconText>
        </IconWrapper>
        :
        status === "NOT_INTERESTED"
          ?
          <IconWrapper>
            <IconCircle>
              <StyledIcon as={ThumbsDown} color="#dc2626" />
            </IconCircle>
            <IconText color="#dc2626">Not Interested</IconText>
          </IconWrapper>
          :
          status === "DELIVERED"
            ?
            <IconWrapper>
              <IconCircle>
                <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" viewBox="1.5rem 1.5rem 1.5rem 1.5rem" fill="none" stroke="grey" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <polyline points="1.5 12 4.5 15 9 9"></polyline>
                  <polyline points="6.5 12 9.5 15 14 9"></polyline>
                </svg>
              </IconCircle>
              <IconText color="grey">Delivered</IconText>
            </IconWrapper>
            :
            status === "READ"
              ?
              <IconWrapper>
                <IconCircle>
                  <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" viewBox="1.5rem 1.5rem 1.5rem 1.5rem" fill="none" stroke="green" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <polyline points="1.5 12 4.5 15 9 9"></polyline>
                    <polyline points="6.5 12 9.5 15 14 9"></polyline>
                  </svg>
                </IconCircle>
                <IconText color="green">Read</IconText>
              </IconWrapper>
              :
              <IconWrapper>
              </IconWrapper>
  )
}


const LoaderIcon = styled.span`
  width: 1.3rem;
  height: 1.3rem;
  border: 1.5px solid var(--lightOrange);
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
`


const IconWrapper1 = styled.div`
  ${props => {
    switch (props.$state) {
      case 'NEW':
        return `
          color: grey;
        `;
      case 'PROCESSING':
        return `
          color: grey;
        `;
      case 'FAILED':
        return `
          color: red;
        `;
      default:
        return `
          color: grey;
        `;
    }
  }}
`;

const StatusMessage = styled.p`
  font-weight: 500;
  font-size: 0.75rem;
  ${props => {
    switch (props.$state) {
      case 'NEW':
        return 'color: var(--color)';
      case 'PROCESSING':
        return 'color: var(--color)';
      case 'FAILED':
        return 'color: var(--color)';
      default:
        return 'color: var(--color)';
    }
  }}
`;

const getStateDetails = (state) => {
  switch (state) {
    case 'NEW':
      return {
        icon: <FileText />,
        message: "Resume Uploaded"
      };
    case 'PROCESSING':
      return {
        icon: <LoaderIcon/>,
        message: "Processing Resume"
      };
    case 'FAILED':
      return {
        icon: <XCircle color="red"/>,
        message: "Processing Failed"
      };
    default:
      return {
        icon: <FileText/>,
        message: "Unknown State"
      };
  }
};


function Row(props) {
  const { row, handleSelectArray, index, filterParams, updateTrigger, setUpdateTrigger, rowsLength, handleSelectChange, status, jdId, page, size } = props;
  const selected = useSelector((state) => state?.invite?.selectedRows);
  const [agencyData, setAgencyData] = useState({});
  const accessToken = useSelector(state => state?.auth?.userData?.accessToken);
  const clientCode = useSelector(state => state?.auth?.userData?.user?.clientCode);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();

  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [isRescheduleOpen, setIsRescheduleOpen] = useState(false);

  const [state, setState] = React.useState({
    right: false,
  });

  const [proctoringResults, setProctoringResults] = useState([]);

  useEffect(() => {
    const getResults = async () => {
      try {
        const res = await getAllProctoringResults(jdId, row?.interview?.userEmail, accessToken);
        if (res) {
          setProctoringResults(res?.data);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        // toast.error(errMsg);
      }
    };
    if (row?.interview?.userEmail) {
      getResults();
    }
  }, []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };


  const handleDownload = async (id, name) => {
    try {
      if (!id) {
        toast.warning("No resume found or invalid resumeId", 5000);
        return;
      }
      const res = await getBlobData(
        `api/media/downloadById?fileType=resume&id=${id}`,
        accessToken,
        clientCode
      );

      const a = document.createElement("a");
      a.href = res;
      a.setAttribute("download", `${name}`);
      a.click();
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  };

  const openDropdown = (index) => {
    setOpenDropdownIndex(index);
  };

  const closeAllDropdowns = () => {
    setOpenDropdownIndex(-1);
  };

  const handleGetAgencyInfo = async (agencyCode) => {
    try {
      const res = await getEmployer(accessToken, agencyCode);
      if (res) setAgencyData(res?.data);
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  }

  const getSourceAndAppliedBy = (source, type) => {
    if (type == "source") {
      if (source == "EMPLOYER_POOL") {
        return "Self"
      }
      else if (source == "AGENCY") {
        return "Agency";
      }
      else {
        return "Candidate"
      }
    }
    else {
      if (source == "EMPLOYER_POOL") {
        return row?.clientCode ?? clientCode;
      }
      else if (source == "AGENCY") {
        return row?.agencyCode;
      }
      else {
        return row?.agencyCode?.toUpperCase();
      }
    }
  }

  const handleUpdateDetails = () => {
    setIsUpdateOpen(true);
  }

  function convertScoreToPercentage(score) {
    return score * 10;
  }

  const handleRescheduleOpen = () => {
    setIsRescheduleOpen(true);
  }

  const allReports = proctoringResults?.filter(data => data?.report !== undefined && data?.report !== null);

  const lastRoundScore = allReports[allReports?.length - 1]?.report?.score || null;

  const { icon, message } = getStateDetails(row?.processingState);



  return (
    <React.Fragment>
      <UpdateMatchedResumeDetails open={isUpdateOpen} onClose={() => setIsUpdateOpen(false)} data={row} handleTrigger={() => setUpdateTrigger(prev => !prev)} />
      {isRescheduleOpen && <ConfigurableBackdropModal component={<RescheduleSlotSelection handleClose={() => setIsRescheduleOpen(false)} interviewId={row?.interviewId} setTriggerRefresh={setUpdateTrigger} />} open={isRescheduleOpen} setOpen={setIsRescheduleOpen} style={{ width: '80%', height: '80%' }} />}
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        className={`${index % 2 == 1 ? "colored" : ""}`}
      >
        <TableCell
          component="th"
          scope="row"
          align="center"
          className="tableCell"
        >
          {((page - 1) * size) + (index + 1)}
        </TableCell>
        <TableCell component="th" scope="row" align="center" className="tableCell">
          {row?.name}
        </TableCell>
        <TableCell align="center" className="tableCell">{row?.email}</TableCell>
        <TableCell align="center" className="tableCell">{row?.contact}</TableCell>
        <TableCell align="center" className="tableCell">{getSourceAndAppliedBy(row?.appliedBy, "source")}</TableCell>
        <TableCell align="center" className="tableCell">{getSourceAndAppliedBy(row?.appliedBy, "appliedBy") == "agency" ? <BoxRow isLast={index >= rowsLength - 2}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "0.2rem" }}><p>{row?.agencyCode}</p>
            <img
              src={iIcon}
              style={{ width: "0.8rem", height: "0.8rem", cursor: "pointer", display: "flex" }}
              className={`i-icon ${openDropdownIndex === index ? "active" : ""
                }`}
              onClick={() => {
                if (openDropdownIndex === index) {
                  closeAllDropdowns();
                }
                else {
                  openDropdown(index);
                  handleGetAgencyInfo(row?.agencyCode);
                }
              }}
            />
          </div>
          <div
            className={`dropdown-content ${openDropdownIndex === index ? "open" : ""
              }`}
            ref={dropdownRef}
          >
            <p><b>Agency Name:</b> <br />{agencyData?.companyName}</p>
            <p><b>Co-ordinator Name:</b> <br />{agencyData?.coOrdinatorName}</p>
          </div></BoxRow> : getSourceAndAppliedBy(row?.appliedBy, "appliedBy")}</TableCell>
        {(filterParams === "IN_PROCESS") && <TableCell align="center" className="tableCell">{row?.round}</TableCell>}
        {(filterParams === "IN_PROCESS" && (status === "COMPLETED" || status === "SCHEDULED")) && <TableCell align="center" className="tableCell">{row?.score}/{row?.maxScore}</TableCell>}
        {(filterParams === "IN_PROCESS" && status === "COMPLETED") && <TableCell align="center" className="tableCell">{lastRoundScore ? lastRoundScore + "%" : "..."}</TableCell>}
        {filterParams !== "IN_PROCESS" && <>{row?.processingState === "SUCCESS" ? <TableCell align="center" className="tableCell" style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', width: '80%', alignItems: 'center', justifyContent: 'center' }}>
          <ProgressBar progress={row?.aiScore ? row?.aiScore : 0} maxScore={10} />
          <span style={{ fontSize: '0.7rem', marginLeft: '0.5rem' }}>{convertScoreToPercentage(row?.aiScore ? row?.aiScore : 0)}%</span>
        </TableCell> : <TableCell align="center" className="tableCell" style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', width: '80%', alignItems: 'center', justifyContent: 'center' }}>
          <IconWrapper1 $state={state}>
            {icon}
          </IconWrapper1>
          <div style={{ marginLeft: '16px' }}>
            <StatusMessage $state={state}>{message}</StatusMessage>
          </div>
        </TableCell>}</>}
        {(filterParams !== "IN_PROCESS" && filterParams !== "DUPLICATE") && <TableCell align="center" className="tableCell"><InterestStatusIcon status={row?.interest} /></TableCell>}
        {(status && status === "SCHEDULED") && <TableCell align="center" className="tableCell">{timeZoneConversion(row?.scheduledAt)}</TableCell>}
        <TableCell
          align="center"
          className="tableCell lastCell"
        >
          {filterParams === "MATCHED" && <img src={editIcon} onClick={() =>
            handleUpdateDetails()
          } />}
          <input
            type="checkbox"
            checked={selected?.find(item => item?.id === row?.id)?.selected}
            onChange={() => handleSelectChange(row)}
            className="checkBox"
          />
          <img src={downloadIcon} onClick={() =>
            handleDownload(row?.resumeId, row?.srcFilename)
          } />
        </TableCell>
        {(status && status === "SCHEDULED") && <TableCell align="center" className="tableCell"><ButtonR onClick={() => handleRescheduleOpen()} >Reschedule</ButtonR></TableCell>}
        {(status && (status === "COMPLETED" || status === "SCHEDULED")) && <TableCell component="th" scope="row" align="center" className="tableCell">
          <div style={{ display: 'flex', gap: '0.6rem', justifyContent: 'center', alignItems: 'center' }}>
            <CommonDrawer toggleDrawer={toggleDrawer} state={state} component={<SeekerInterviewDetails jdId={jdId} data={row?.interview} employer={true} proctoringResults={proctoringResults} />} />
            <img src={visibleIcon} className="icon" onClick={toggleDrawer("right", true)} />
          </div>
        </TableCell>}
      </TableRow>
    </React.Fragment>
  );
}

export default function MatchedResumes({ filterParams, interviewStatus, setTotalRowsCount }) {
  const { jdId } = useParams();
  const [tableRows, setTableRows] = useState([]);
  const [idToSendInvite, setIdToSendInvite] = useState(null);
  const selected = useSelector((state) => state?.invite?.selectedRows);
  const [selectedArray, setSelectedArray] = useState([]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.auth.userData.accessToken);
  const clientCode = useSelector(
    (state) => state.auth.userData.user.clientCode
  );
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [search, setSearch] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState('');

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const [selectedAll, setSelectedAll] = useState([]);

  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [trackerData, setTrackerData] = useState([]);
  const [matchedData, setMatchedData] = useState([]);

  const [isCheckInterestOpen, setIsCheckInterestOpen] = useState(false);


  const dropdownRef3 = useRef(null);

  const [jdBox, setJdBox] = useState(false);
  const [jdInfo, setJdInfo] = useState({});

  useEffect(() => {
    setTotalRowsCount(total);
  }, [total]);

  useEffect(() => {
    const length = Math.max(0, Math.ceil(total / size)); // Ensure length is non-negative
    setSelectedAll(Array.from({ length }, () => false));
  }, [total, size]);

  const handleSizeChange = (event) => {
    setSize(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handlePageChange = (change) => {
    if (change && (page < Math.ceil(+total / +size))) {
      setPage((prev) => prev + 1);
    } else if (!change && page > 1) {
      setPage((prev) => prev - 1);
    }
  };

  // running an effect to empty selectedArray whenever filter value changes
  useEffect(() => {
    setSelectedArray([]);
    dispatch(resetSelection());
  }, [filterParams]);



  useEffect(() => {
    if (!accessToken || !clientCode) {
      toast.warning("Login First");
      navigate("/login");
    }

    async function getTrackersData() {
      try {
        const trackerRes = await getAllTrackersWithInterviewStatus(jdId, accessToken, search ? 1 : page, search ? 1000000 : size, filterParams, clientCode, (filterParams === "IN_PROCESS" && interviewStatus) ? interviewStatus : "");

        if (trackerRes) {
          setTotal(trackerRes?.data?.total);
          setTrackerData(trackerRes?.data?.data);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }

    // func to get all matched resume
    async function getData() {
      try {
        const resObj = await getMatches(jdId, accessToken, clientCode, search ? 1 : page, search ? 1000000 : size, filterParams !== "DUPLICATE" ? false : true);
        if (resObj) {

          setMatchedData(resObj?.data[0]?.records?.data);

          if (filterParams === 'MATCHED' || filterParams === 'DUPLICATE') {
            setTotal(resObj?.data[0]?.records?.total);

            setTableRows(resObj?.data[0]?.records?.data);
          }
          setIdToSendInvite(resObj?.data[0]?.jdId);
        }
      } catch (error) {
        const errMsg =
          error?.response?.data?.notify?.message ||
          "An error occurred. Please try again.";
        toast.error(errMsg, 8000);
      }
    }

    getData();

    // tracker data api to be called when filter params are valid, like here:- SHORTLISTED & NOT_SHORTLISTED
    if (filterParams === 'SHORTLISTED' || filterParams === 'NOT_SHORTLISTED' || filterParams === "IN_PROCESS") {
      getTrackersData();
    }

  }, [page, size, filterParams, search, updateTrigger]);


  useEffect(() => {

    // func to filter matched resumes data on the basis of trackers status.
    const filterData = (array) => {
      array = array.reduce((acc, it) => {
        let match = it?.matchedRecord;
        let interview = it?.interview;
        let obj = {
          id: it?.id,
          matchId: match?.id,
          name: match?.name ?? interview?.userName,
          email: match?.email ?? it?.userEmail,
          contact: match?.contact,
          appliedBy: match?.appliedBy,
          agencyCode: it?.agencyCode,
          score: match?.score ?? interview?.score,
          maxScore: match?.maxScore ?? interview?.maxScore,
          aiScore: match?.aiScore,
          resumeId: match?.resumeId,
          srcFilename: match?.srcFilename,
          interviewId: interview?.id,
          round: it?.stage,
          interview: it?.interview,
          scheduledAt: interview?.scheduledAt,
          processingState: match?.processingState,
        }

        return [...acc, obj]
      }, []);

      return array
    }

    if (filterParams === 'SHORTLISTED' || filterParams === 'NOT_SHORTLISTED' || filterParams === "IN_PROCESS") {
      const filteredData = filterData(trackerData);
      setTableRows(filteredData);
    }

  }, [page, size, filterParams, matchedData, trackerData, search, updateTrigger]);


  useEffect(() => {
    if (searchValue?.trim()) {
      setSearch(true);

      setFilteredData(() =>
        tableRows?.filter(
          (item) =>
            item?.name?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
            item?.email?.toLowerCase()?.includes(searchValue.toLowerCase())
        ))
    } else {
      setSearch(false);
    }
  }, [searchValue, updateTrigger])


  const handleSelectArray = (id, action) => {
    if (action) {
      setSelectedArray((prev) => [...prev, id]);
    } else {
      setSelectedArray((prev) => prev.filter((item) => item?.id !== id));
    }
  };



  const handleSelectChange = (row) => {
    const isSelected = selected?.find(item => item?.id === row?.id);
    const isChecked = isSelected ? isSelected?.selected : false;

    dispatch(toggleSelected({ id: row?.id, value: !isChecked }));

    if (isChecked) {
      handleSelectArray(row?.id, false);
    } else {
      handleSelectArray(row, true);
    }
  }

  const handleSelectAllCheckBox = async (selectStatus, index) => {

    if (!selectStatus && !search) {
      tableRows?.forEach(row => {
        dispatch(toggleSelected({ id: row?.id, value: true }));
        handleSelectArray(row, true);
      })
    } else if (!selectStatus && search) {
      filteredData?.forEach(row => {
        dispatch(toggleSelected({ id: row?.id, value: true }));
        handleSelectArray(row, true);
      })
    } else if (selectStatus && !search) {
      tableRows?.forEach(row => {
        dispatch(toggleSelected({ id: row?.id, value: false }));
        handleSelectArray(row?.id, false);
      })
    } else if (selectStatus && search) {
      filteredData?.forEach(row => {
        dispatch(toggleSelected({ id: row?.id, value: false }));
        handleSelectArray(row?.id, false);
      })
    }

    setSelectedAll(prevState => {
      const updatedArray = [...prevState];
      updatedArray[index] = !updatedArray[index];
      return updatedArray;
    });
  }

  const handleSchedule = () => {
    if (selectedArray?.length > 0) {
      const uniqueSelectedData = selectedArray?.filter((item, index, self) =>
        index === self?.findIndex((t) => (
          t?.id === item?.id
        ))
      );
      dispatch(addResumes([...uniqueSelectedData]));
      dispatch(addSelectedJd(jdId));
      navigate(`/schedule/invite/${jdId}`);
    } else {
      toast.warning("Select Resume First");
    }
  };

  console.log(selectedArray);

  // func to update tracker status as Shortlist or Rejected
  const handleTrackerUpdate = async (val) => {
    try {
      setLoading(val);
      const payloadData = {
        "jdId": jdId,
        "matchIds": selectedArray?.map(user => user?.id),
        "status": val,
        "trackerIds": [],
      }

      const updatedRes = await addTrackers(payloadData, accessToken, clientCode);

      if (updatedRes) {
        setUpdateTrigger(!updateTrigger);
        toast.success(`${val === 'SHORTLISTED' ? 'Shortlisted' : 'Rejected'} Successfully`);
        setSelectedArray([]);
        dispatch(resetSelection());
        setLoading('');
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
      setLoading('');
    }
  }


  const handleCheckInterest = () => {
    setIsCheckInterestOpen(true)
  }


  const handleExistingTrackerUpdate = async (val) => {
    try {
      setLoading(val);
      const payloadData = {
        "jdId": jdId,
        "status": val,
        "trackerIds": selectedArray?.map(user => user?.id),
      }

      const updatedRes = await updateTrackers(payloadData, accessToken, clientCode);

      if (updatedRes) {
        setUpdateTrigger(!updateTrigger);
        toast.success(`${val === 'SHORTLISTED' ? 'Shortlisted' : val === 'HOLD' ? 'Candidate is now on Hold' : val === "NOT_SELECTED" ? "Candidate Moved out of Interview" : 'Rejected'} Successfully`);
        setSelectedArray([]);
        dispatch(resetSelection());
        setLoading('');
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
      setLoading('');
    }
  }

  const handleDeleteTracker = async () => {
    try {
      setLoading('MATCHED');
      const updatedRes = Promise.all(selectedArray?.map(async (user) => {
        await deleteTracker(user?.id, accessToken);
      }));

      if (updatedRes) {
        setUpdateTrigger(!updateTrigger);
        toast.success(`Moved to Matched Successfully`);
        setSelectedArray([]);
        dispatch(resetSelection());
        setLoading('');
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
      setLoading('');
    }
  }

  const handleGetJdInfo = async (jdId) => {
    try {
      const res = await getJdByClientCode(jdId, accessToken, clientCode);
      if (res) setJdInfo(res?.data);
    } catch (error) {
      const errMsg =
        error?.response?.data?.notify?.message ||
        "An error occurred. Please try again.";
      toast.error(errMsg, 8000);
    }
  }

  const handleCheckInterestModalClose = () => {
    setIsCheckInterestOpen(false);
    dispatch(resetSelection());
    setUpdateTrigger(!updateTrigger);
    setSelectedArray([]);
  }

  return (
    <StyledDiv>
      <CheckInterestSlotModal jdId={jdId} open={isCheckInterestOpen} onClose={() => handleCheckInterestModalClose()} ids={selectedArray?.map(obj => obj?.matchId || obj?.id)?.join(',')} />
      <Content>
        <TableContainer component={Paper} className="tableBox">
          <ModalHOC
            openNewInterviewModal={open}
            setOpenNewInterviewModal={setOpen}
            Component={ScheduleModal}
            array={[...selectedArray, idToSendInvite]}
          />

          <span className='mainTitle'>
            {/* <span className="title">{filterParams === 'MATCHED' ? 'Matched' : filterParams === 'SHORTLISTED' ? 'Shortlisted' : 'Rejected'} Resumes for JD ID: {jdId} </span> */}
            <BoxRow >
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "0.2rem" }}><p>{filterParams === 'MATCHED' ? 'Matched' : filterParams === 'SHORTLISTED' ? 'Shortlisted' : (filterParams === "IN_PROCESS" && interviewStatus === "COMPLETED") ? "Completed" : (filterParams === "IN_PROCESS" && interviewStatus === "SCHEDULED") ? "Scheduled" : filterParams === "NOT_SHORTLISTED" ? "Rejected" : "Duplicate"} Resumes </p>
                <img
                  src={iIcon}
                  style={{ width: "0.8rem", height: "0.8rem", cursor: "pointer", display: "flex" }}
                  className={`i-icon ${jdBox ? "active" : ""
                    }`}
                  onClick={() => {
                    if (jdBox) {
                      setJdBox(false)
                    }
                    else {
                      setJdBox(true)
                      handleGetJdInfo(jdId)
                    }
                  }}
                />
              </div>
              <div
                className={`dropdown-content ${jdBox ? "open" : ""
                  }`}
                ref={dropdownRef3}
              >
                <p><b>JD Title:</b> <br />{jdInfo?.title}</p>
                <p><b>Hiring Manager:</b> <br />{jdInfo?.hiringManager}</p>
              </div></BoxRow>
            <Button1 onClick={() => navigate("/schedule")}>Back</Button1>
          </span>
          <SearchBarContainer>
            <TableSearchBar value={searchValue} setValue={setSearchValue} />
            <Label>Select All
              <input
                type="checkbox"
                className="checkBox"
                checked={selectedAll[page]}
                onChange={() => handleSelectAllCheckBox(selectedAll[page], page)}
              /></Label>
          </SearchBarContainer>
          <Table aria-label="collapsible table">
            <TableHead className="tableHead">
              <TableRow>
                <TableCell align="center" className="tableCell">S. No.</TableCell>
                <TableCell align="center" className="tableCell">Name</TableCell>
                {/* <TableCell align="center" className="tableCell">Match Percentage</TableCell> */}
                <TableCell align="center" className="tableCell">Email</TableCell>
                <TableCell align="center" className="tableCell">Contact</TableCell>
                <TableCell align="center" className="tableCell">Source</TableCell>
                <TableCell align="center" className="tableCell">Agency Code</TableCell>
                {filterParams === "IN_PROCESS" && <TableCell align="center" className="tableCell">Current Round</TableCell>}
                {(interviewStatus && (interviewStatus === "COMPLETED" || interviewStatus === "SCHEDULED")) && <TableCell align="center" className="tableCell">Score (Last Round)</TableCell>}
                {(interviewStatus && (interviewStatus === "COMPLETED")) && <TableCell align="center" className="tableCell">Credibility (Last Round)</TableCell>}


                {filterParams !== "IN_PROCESS" && <TableCell align="center" className="tableCell">
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: 'center',
                      gap: "0.4rem",
                    }}
                  >
                    Resume Relevance
                    <img
                      className="iIcon"
                      src={iIcon}
                      data-tooltip-id="AI-Score"
                      data-tooltip-html="<div>JD Resume match Relevance score on a scale of 100</div>"
                    />
                  </span>
                  <Tooltip id="AI-Score" />
                </TableCell>}
                {(filterParams !== "IN_PROCESS" && filterParams !== "DUPLICATE") && <TableCell align="center" className="tableCell">Interest Status</TableCell>}
                {(interviewStatus && interviewStatus === "SCHEDULED") && <TableCell align="center" className="tableCell">Scheduled Time</TableCell>}
                <TableCell align="center" className="tableCell">Actions</TableCell>
                {(interviewStatus && interviewStatus === "SCHEDULED") && <TableCell align="center" className="tableCell">Reschedule</TableCell>}
                {(interviewStatus && (interviewStatus === "COMPLETED" || interviewStatus === "SCHEDULED")) && <TableCell align="center" className="tableCell">Details</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody className="tableBody">
              {search ?
                filteredData?.map((row, index) => (
                  <Row
                    key={index}
                    row={row}
                    handleSelectArray={handleSelectArray}
                    handleSelectChange={handleSelectChange}
                    index={index}
                    filterParams={filterParams}
                    updateTrigger={updateTrigger}
                    setUpdateTrigger={setUpdateTrigger}
                    rowsLength={filteredData?.length}
                    status={interviewStatus}
                    jdId={jdId}
                    page={page}
                    size={size}
                  />
                )) :
                tableRows?.map((row, index) => (
                  <Row
                    key={index}
                    row={row}
                    handleSelectArray={handleSelectArray}
                    handleSelectChange={handleSelectChange}
                    index={index}
                    filterParams={filterParams}
                    updateTrigger={updateTrigger}
                    setUpdateTrigger={setUpdateTrigger}
                    rowsLength={tableRows?.length}
                    status={interviewStatus}
                    jdId={jdId}
                    page={page}
                    size={size}
                  />
                ))
              }
            </TableBody>
          </Table>

          {!search && <div className="paginationBox">
            <PaginationSizeFilter
              size={size}
              handleSizeChange={handleSizeChange}
            />
            <Pagination
              total={total}
              size={size}
              page={page}
              handlePageChange={handlePageChange}
              setPage={setPage}
            />
          </div>}

        </TableContainer>
        <div className="btnBox">
          {(filterParams === 'MATCHED' || filterParams === "SHORTLISTED") && <Button className="btn" onClick={() => handleCheckInterest()} disabled={selectedArray?.length === 0} >Check Interest</Button>}
          {(filterParams === 'MATCHED') && <Button className="btn" onClick={() => handleTrackerUpdate('SHORTLISTED')} disabled={loading === "SHORTLISTED" || selectedArray?.length === 0} >{loading === "SHORTLISTED" ? "Shortlisting..." : "Mark Shortlisted"}</Button>}
          {filterParams === 'MATCHED' && <Button className="btn" onClick={() => handleTrackerUpdate('NOT_SHORTLISTED')} disabled={loading === "NOT_SHORTLISTED" || selectedArray?.length === 0} >{loading === "NOT_SHORTLISTED" ? "Rejecting..." : "Mark Rejected"}</Button>}

          {(filterParams === 'NOT_SHORTLISTED') && <Button className="btn" onClick={() => handleExistingTrackerUpdate('SHORTLISTED')} disabled={loading === "SHORTLISTED" || selectedArray?.length === 0} >{loading === "SHORTLISTED" ? "Shortlisting..." : "Mark Shortlisted"}</Button>}
          {(filterParams === 'NOT_SHORTLISTED') && <Button className="btn" onClick={() => handleDeleteTracker()} disabled={loading === "MATCHED" || selectedArray?.length === 0} >{loading === "MATCHED" ? "Moving to matched..." : "Move to Matched"}</Button>}
          {filterParams === 'SHORTLISTED' && <Button className="btn" onClick={() => handleExistingTrackerUpdate('NOT_SHORTLISTED')} disabled={loading === "NOT_SHORTLISTED" || selectedArray?.length === 0} >{loading === "NOT_SHORTLISTED" ? "Rejecting..." : "Mark Rejected"}</Button>}
          {(filterParams === 'IN_PROCESS') && <Button onClick={() => handleExistingTrackerUpdate('HOLD')} className="btn" disabled={selectedArray?.length === 0 || loading === "HOLD"}>Put on Hold</Button>}
          {(filterParams === 'IN_PROCESS') && <Button onClick={() => handleExistingTrackerUpdate('NOT_SELECTED')} className="btn" disabled={selectedArray?.length === 0 || loading === "NOT_SELECTED"}>Move out from Interview</Button>}
          {(filterParams === 'SHORTLISTED' || interviewStatus === "COMPLETED") && <Button onClick={() => handleSchedule()} className="btn" disabled={selectedArray?.length === 0}>{interviewStatus === "COMPLETED" ? "Move to Next Round" : "Schedule Interview"}</Button>}

        </div>
      </Content>
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Content = styled.div`
  width: 98%;
  padding: 0 1%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .colored {
    background-color: #ececec;
  }

  .paginationBox {
    display: flex;
    justify-content: end;
    gap: 2rem;
    margin: 1rem 3rem 1.5rem 0;
  }

  .mainTitle {
    font-size: 0.9rem;
    font-weight: 600;
    margin: 1rem 0 1rem 3rem;
    width: calc(98% - 3rem);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 0.9rem;
      font-weight: 600;
    }
}
  

  .prev {
    background-color: var(--lightOrange);
    padding: 0.1rem;
    position: absolute;
    top: 4.8rem;
    left: 1.5rem;
    color: var(--white);
  }

  .prev:hover {
    color: var(--color);
  }

  .tableBox {
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.5rem;
  }

  .MuiTableCell-root {
    border: none;
  }

  .MuiTableRow-root {
    border-bottom: none;
  }

  .selected {
    background-color: #d9fbf9;
    color: white;
  }


  .tableHead {
    background-color: #d1fff0;
    width: 100%;
  
    .tableCell {
      font-size: 0.9rem;
      font-weight: 500;
      font-family: var(--font);
      color: var(--color);
    }
    
    .iIcon {
      width: 1.1rem;
    }
  }
  
  .tableBody {
    width: 100%;
  
    .tableCell {
      font-size: 0.8rem;
      font-weight: 400;
      font-family: var(--font);
      color: var(--color);
    }
    
    .lastCell {
          min-width: 10rem;
    }
    
  }

  .btnBox {
    display: flex;
    gap: 1rem;
    background-color: var(--white);
    padding: 1rem 2rem;
    border-radius: 1rem;
    position: fixed;
    bottom: 1rem;
  
  }


  .checkBox {
    cursor: pointer;
    width: 1.2rem;
    height: 1.2rem;
    text-align: center;

  }

  img {
    width: 1.2rem;
    height: 1.2rem;
    cursor: pointer;
    padding-top: 0.5rem;
    text-align: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

  }
`;


const Button1 = styled.button`
  padding: 0.5rem 0.8rem;
  background-color: var(--lightOrange);
  color: #fff;
  border: none;
  border-radius: 0.3rem;
  cursor: pointer;
  align-self: center;
  font-size: 0.9rem;
  font-weight: 600;
  font-family: var(--font);
  cursor: pointer;
`

const Button = styled.button`
  padding: 0.5rem 1rem;
  margin-top: 0rem;
  background-color: var(--lightOrange);
  border: none;
  color: var(--white);
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 0.5rem;
  cursor: pointer;
  font-family: var(--font);

  &:disabled {
        cursor: not-allowed;
        color: #F4F4F4;
    }

`

const ButtonR = styled.button`
  padding: 0.5rem 1rem;
  margin-top: 0rem;
  background-color: var(--lightOrange);
  border: none;
  color: var(--white);
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 0.5rem;
  cursor: pointer;
  font-family: var(--font);

  &:disabled {
        cursor: not-allowed;
        color: #F4F4F4;
    }

`


const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 96%;
  margin: 0.5rem auto;
  height: 3rem;
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 0rem 1rem;
  gap: 1rem;

`;

const BoxRow = styled.div`
  position: relative;
  display: inline-block;

  .i-icon {
    cursor: pointer;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--white);
    box-shadow: 0 0.3rem 0.5rem 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 10%;
    border-radius: 0.5rem;
    font-size: 0.7rem;
    min-width: 10rem;
    justify-content: start;
    padding: 0.5rem 0.5rem;
    botton: 0.3rem;
    ${(props) =>
    props.isLast &&
    css`
        bottom: 1.4rem;
        right: 10%;
      `}
  }

  .dropdown-content span {
    padding: 0.3rem 0.8rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--color);
    cursor: pointer;
  }

  .dropdown:hover .dropdown-content,
  .dropdown-content.open {
    display: block;
  }

  .threeDotIcon {
    width: 0.6rem;
    height: 0.6rem;
    cursor: pointer;
    border: 0.08rem solid grey;
    padding: 0.15rem;
    border-radius: 0.2rem;
  }
`;


const Label = styled.label`
font-size: 0.85rem;
font-weight: 600;
display: flex;
gap: 0.25rem;
align-items: center;

`