import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import styled from 'styled-components';
import { faqData } from '../utils/allHomepageData';


const Container = styled.div`
  margin: 0 auto;
  background-color: transparent;
  color: var(--color);
  padding: 1rem 2.5%;
  width: 95%;
  box-sizing: border-box;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
  border: 0.05rem solid lightgrey;
  font-family: var(--font);

`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 2rem;

  h2 {
    text-align: center;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  
  background: linear-gradient(
    135deg,
    #479e9c 24%,
    #000000 100%
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;

  position: relative;
  transform: rotate(0deg);
  }

  p {
    font-size: 1rem;
    font-weight: 500;
    color: #4b5563;
    margin: 0 auto;
  }
`;

const Category = styled.div`
  margin-bottom: 0rem;

  h3 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #1f2937;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 1.5rem;

    svg {
      margin-right: 0.5rem;
      color: #3b82f6;
    }
  }
`;

const QuestionContainer = styled.div`
  border-bottom: 1px solid #e5e7eb;
  &:last-child {
    border-bottom: none;
  }
`;

const QuestionButton = styled.button`
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  background: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f9fafb;
  }

  span {
  font-size: 1rem;
  font-family: var(--font);
    font-weight: 500;
    color: #111827;
  }

  svg {
    transition: transform 0.2s;
    transform: ${({ isActive }) => (isActive ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
`;

const Answer = styled.div`
  padding: 0.75rem;
  color: #4b5563;
  font-size: 1rem;
  font-weight: 500;
`;

const GradientIcon = ({ Icon }) => (
    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <linearGradient id="iconGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="24%" stopColor="#479e9c" />
                <stop offset="100%" stopColor="#000000" />
            </linearGradient>
        </defs>
        <Icon stroke="url(#iconGradient)" fill="none" strokeWidth={2} />
    </svg>
);

const FAQSection = () => {
    const [activeQuestion, setActiveQuestion] = useState(null);

    return (
        <Container>
            <Header>
                <h2>Got Questions? We've Got Answers</h2>
                <p>Find all the information you need to get started with RegorTalent.</p>
            </Header>

            {faqData?.map((category, catIndex) => (
                <Category key={catIndex}>
                    <h3>
                        <GradientIcon Icon={category.icon} />
                        {category?.category}
                    </h3>
                    {category?.questions?.map((faq, faqIndex) => (
                        <QuestionContainer key={faqIndex}>
                            <QuestionButton
                                onClick={() =>
                                    setActiveQuestion(
                                        activeQuestion === `${catIndex}-${faqIndex}` ? null : `${catIndex}-${faqIndex}`
                                    )
                                }
                                isActive={activeQuestion === `${catIndex}-${faqIndex}`}
                            >
                                <span>{faq?.question}</span>
                                <ChevronDown />
                            </QuestionButton>
                            {activeQuestion === `${catIndex}-${faqIndex}` && <Answer>{faq?.answer}</Answer>}
                        </QuestionContainer>
                    ))}
                </Category>
            ))}
        </Container>
    );
};

export default FAQSection;
