import React from 'react';
import styled from 'styled-components';
import AiFeatureCard from './AiFeatureCard';
import AiAssesmentCard from './AiAssessmentCard';
import AiProctoringCard from './AiProctoringCard';

const Wrapper = styled.div`
  width: 95%;
  margin: 0 auto;
  padding: 2rem;
  border-radius: 1rem;
  box-sizing: border-box;
  position: relative;
  background: linear-gradient(to bottom right, #ebf8ff, #ffffff, #d1fae5);

`;


const CircleEffect = styled.div`
  position: absolute;
  ${({ position }) => position}: 50%;
  width: 16rem;
  height: 16rem;
  background-color: rgba(225, 240, 255, 0.2);
  border-radius: 50%;
  filter: blur(3xl);
  z-index: -5;
`;

const Header = styled.div`
display: flex;
flex-direction: column;
width: 100%;
align-items: center;
margin-bottom: 2rem;

`

const Heading = styled.h1`
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 0rem;
  background: linear-gradient(to right, #3b82f6, var(--lightOrange), #3b82f6);
  -webkit-background-clip: text;
  color: transparent;
  padding: 0;
  margin: 0;
`;

const Subheading = styled.span`
  text-align: center;
  font-size: 1.125rem;
  color: #4b5563;
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 2rem;
  margin-bottom: 0rem;

  @media(max-width: 600px){
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  @media(max-width: 400px){
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;


const FeatureShowcase = ({aiFeatures}) => {

    return (
        <Wrapper>
            <CircleEffect position="top" />
            <CircleEffect position="bottom" />
            <Header>
                <Heading>Revolutionizing Recruitment with Next-Gen AI</Heading>
                <Subheading>Unleashing cutting-edge AI innovations every month to transform your hiring experience.</Subheading>
            </Header>

            <CardGrid>
                <AiAssesmentCard
                    title="AI-Powered Assessment Engine"
                    description="Our AI evaluates technical & soft skills with 98.5% accuracy"
                    technicalScore={aiFeatures?.evaluationData?.technicalScore}
                    communicationMetrics={aiFeatures?.evaluationData?.communicationMetrics}
                />

                <AiProctoringCard
                    title="Advanced Remote Proctoring"
                    description="99.9% accurate fraud detection with real-time AI monitoring"
                    proctoringFeatures={aiFeatures?.proctoringFeatures}
                    metrics={aiFeatures?.proctoringMetrics}
                />

                {aiFeatures?.cards?.map((card, index) => (
                    <AiFeatureCard key={index} title={card?.title} description={card?.description} metrics={card?.metrics} />
                ))}
            </CardGrid>
        </Wrapper>
    );
};

export default FeatureShowcase;
