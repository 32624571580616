import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { styled } from "styled-components";
import landingPageTopImg from '../../../assets/homepageBannerImageAI.webp';

const HeroSectionTop = () => {
  const navigate = useNavigate();

  useEffect(() => {
    preloadImage(landingPageTopImg);
  }, []);

  const handleSubmit = () => {
    navigate("/signup");
  };

  const preloadImage = (src) => {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.href = src;
    link.as = 'image';
    document.head.appendChild(link);
  };

  return (
    <StyledContent>
      <DottedBackground>
        <section className="landing-page">
          <div className="landingContent">
            <article className="left">
              <div className="textContainer">
                <h1 className="title">
                  <span className="titleText1">WELCOME TO THE </span>
                  <span className="titleText2">FUTURE <span className="titleText3">OF </span></span>
                  <span className="titleText4">Recruitment!</span>
                </h1>
                <p className="desc">AI-Powered, Smarter,<br /> Structured and More Efficient!</p>
              </div>
              <button onClick={handleSubmit} className="btn" aria-label="Try now">Try Now</button>
            </article>
            <aside className="right">
              <img
                src={landingPageTopImg}
                alt="AI-powered recruitment banner"
                fetchpriority="high"
              />
            </aside>
          </div>
        </section>
      </DottedBackground>
    </StyledContent>
  );
};

export default HeroSectionTop;

const DottedBackground = styled.div`
  background-image: radial-gradient(grey 0.5px, transparent 0.5px);
  background-size: 25px 25px;
  padding-bottom: 2rem;
  padding-top: 0rem;
  display: flex;
  flex-direction: column;
  align-items: center;

`;

export const StyledContent = styled.div`
  background: linear-gradient(
  180deg, 
  rgba(132, 255, 253, 0.1) 30%,
  rgba(245, 255, 255, 1) 61%,
  rgba(230, 236, 236, 0.89) 73%,
  rgba(255, 255, 255, 1) 100%);
  width: 100%;

  box-sizing: border-box;

  .landing-page {
    margin-top: 6rem;
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  // .landing-page::before {
  //   content: "";
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   background-color: var(--lightOrange);
  //   clip-path: polygon(0% 0%, 100% -30%, -15% 100%, 0% 100%);
  //   transform: rotate(180deg);
  // }

  .landingContent {
    z-index: 11;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 2rem;
    margin: 2rem 0rem;
  }

  .left {
    width: 100%;
    grid-column: 1/2;
    grid-row: 1/3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    @media(max-width: 600px) {
      grid-column: 1/3;
      grid-row: 1/2;
    }

    .textContainer {
      display: flex;
      flex-direction: column;
      gap: 0rem;

      .desc {
        font-size: 1.3rem;
        font-weight: 600;
        text-align: center;
        line-height: 1.7rem;
        font-family: 'Inria Serif', serif;
        background: linear-gradient(
    90deg, 
    #000000 24%, 
rgb(42, 100, 93) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      font-family: 'Inria Serif', serif;
      background: linear-gradient(
    90deg, 
    #000000 24%, 
rgb(42, 100, 93) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;


      .titleText1 {
        font-size: 2.5rem;
        font-weight: 600;
      }

      .titleText2 {
        font-size: 3.5rem;
        font-weight: 600;

        .titleText3 {
          font-size: 2.5rem;
          font-weight: 400;
        }
      }

      .titleText4 {
        font-size: 3.5rem;
        font-weight: 600;
      }
    }

    .btn {
      background-color: var(--lightOrange);
      border: none;
      padding: 0.5rem 0.8rem;
      color: var(--white);
      font-size: 0.9rem;
      font-weight: 600;
      font-family: var(--font);
      border-radius: 0.3rem;
      cursor: pointer;
    }

    .btn:hover {
      background-color: var(--white);
      color: black;
      border: 0.1rem solid var(--lightOrange);
      padding: 0.4rem 0.7rem;
    }
  }

  .right {
    width: 100%;
    grid-column: 2/3;
    grid-row: 1/3;

    @media(max-width: 600px) {
      grid-column: 1/3;
      grid-row: 2/3;
    }
  }

  .right > img {
    width: 100%;
  }
`;
