import styled from 'styled-components';
import { Lightbulb, BarChart2, Activity, BookOpen, Users, Clock } from 'lucide-react';

const Container = styled.div`
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: white;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  background: linear-gradient(
    90deg, 
    #2662EB 24%, 
    #524FEA 30%,
rgb(198, 53, 234) 90%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-family: 'Inria Serif', serif;

`;

const Subtitle = styled.p`
  text-align: center;
  color:rgb(26, 27, 28); /* Tailwind's text-gray-600 */
  margin-bottom: 2rem;
  font-size: 1.2rem;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 72rem;
  margin: 0 auto;
`;

const FeatureCard = styled.div`
  text-align: start;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`;

const IconWrapper = styled.div`
  margin-bottom: 1rem;
  font-size: 3rem;
  color:rgb(239, 242, 248); /* Tailwind's text-blue-600 */
  background: linear-gradient(
    90deg, 
    #524FEA 20%,
rgb(198, 53, 234) 90%
  );
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
`;

const FeatureTitle = styled.h3`
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
`;

const FeatureDescription = styled.p`
  color:rgb(40, 42, 44); /* Tailwind's text-gray-500 */
`;

const Features = () => {
  const features = [
    {
      icon: Lightbulb,
      title: 'AI-Powered Mock Tests',
      description:
        'Adaptive question generation based on your performance and learning patterns.',
    },
    {
      icon: BarChart2,
      title: 'Real-time Analytics',
      description:
        'Comprehensive performance tracking with detailed insights and improvement suggestions.',
    },
    {
      icon: Activity,
      title: 'Adaptive Learning Paths',
      description:
        'Personalized study routes that evolve with your progress and learning speed.',
    },
    {
      icon: BookOpen,
      title: 'Subject-Specific Prep',
      description:
        'Focused content and practice materials, tailored to each subject and exam pattern.',
    },
    {
      icon: Users,
      title: 'Peer Comparison',
      description:
        'Compare your performance with peers and track your percentile ranking.',
    },
    {
      icon: Clock,
      title: 'Time Management Tools',
      description:
        'Smart scheduling and time tracking features to optimize your study sessions.',
    },
  ];

  return (
    <Container>
      <Title>
        Revolutionary Features
      </Title>
      <Subtitle>
        Cutting-edge technology meets personalized learning to transform your exam
        preparation journey
      </Subtitle>
      <FeatureGrid>
        {features.map((feature, index) => (
          <FeatureCard key={index}>
            <IconWrapper>
              <feature.icon />
            </IconWrapper>
            <FeatureTitle>{feature.title}</FeatureTitle>
            <FeatureDescription>{feature.description}</FeatureDescription>
          </FeatureCard>
        ))}
      </FeatureGrid>
    </Container>
  );
};

export default Features;
