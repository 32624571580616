import { CheckCircle } from 'lucide-react';
import React from 'react';
import styled from 'styled-components';

const StyledCard = styled.div`
  padding: 1.5rem;
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  &:hover {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  }
`;

const CardHeader = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
margin-bottom: 1rem;
gap: 0.5rem;

`

const CardTitleText = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  color: #1f2937;
  padding: 0;
  margin: 0;
`;

const CardDescription = styled.p`
  color: #6b7280;
  margin-top: 0rem;

`;

const CardContent = styled.div`
  padding-top: 0rem;
`;

const GradientBackground = styled.div`
  background: linear-gradient(to bottom right, #e0f2fe, #d1fae5);
  border-radius: 1rem;
  padding: 1.5rem;
`;

const MetricContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const MetricText = styled.div`
  text-align: center;
`;

const MetricValue = styled.div`
  font-size: 2.25rem;
  font-weight: bold;
  color: #2563eb;
`;

const MetricLabel = styled.div`
  font-size: 0.875rem;
  color: #6b7280;
`;

const CircleIconContainer = styled.div`
  height: 4rem;
  width: 4rem;
  background-color: #c7d2fe;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProgressBarContainer = styled.div`
  margin-bottom: 1rem;
`;

const ProgressBarLabel = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25rem;
`;

const ProgressBarBackground = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 0.5rem;
  height: 0.5rem;
`;

const ProgressBarFill = styled.div`
  background: linear-gradient(to right, #3b82f6, #10b981);
  height: 0.5rem;
  border-radius: 0.5rem;
  transition: all 0.5s;
`;

const AiAssesmentCard = ({ title, description, technicalScore, communicationMetrics }) => (
    <StyledCard>
        <CardHeader>
            <CardTitleText>{title}</CardTitleText>
            <CardDescription>{description}</CardDescription>
        </CardHeader>
        <CardContent>
            <GradientBackground>
                <MetricContainer>
                    <MetricText>
                        <MetricValue>{technicalScore}/10</MetricValue>
                        <MetricLabel>Technical Score</MetricLabel>
                    </MetricText>
                    <CircleIconContainer>
                        <CheckCircle style={{ width: '2rem', height: '2rem', color: '#2563eb' }} />
                    </CircleIconContainer>
                </MetricContainer>
                {communicationMetrics &&
                    Object.entries(communicationMetrics)?.map(([key, value]) => (
                        <ProgressBarContainer key={key}>
                            <ProgressBarLabel>
                                <span style={{ fontSize: '0.875rem', color: '#6b7280' }}>{key.replace(/([A-Z])/g, ' $1')}</span>
                                <span style={{ fontSize: '0.875rem', fontWeight: '500', color: '#4b5563' }}>{value}%</span>
                            </ProgressBarLabel>
                            <ProgressBarBackground>
                                <ProgressBarFill style={{ width: `${value}%` }} />
                            </ProgressBarBackground>
                        </ProgressBarContainer>
                    ))}
            </GradientBackground>
        </CardContent>
    </StyledCard>
);

export default AiAssesmentCard;
