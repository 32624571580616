import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getTestByJdId } from '../../../../functions/api/employers/employerTest/getTestByJdId'
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import attachIcon from '../../../../assets/icons/attach.png'
import { attachJobInfo } from '../../../../functions/api/employers/employerTest/attachJobInfo';
import ModalHOC from '../../SeekerDashboard/ModalHOC';
import AttachTestForm from '../commonComponents/AttachTestForm';

const AttachTestBox = ({ jdId, setIsTestAttached, setAttachedTestData, title=true }) => {
    const accessToken = useSelector((state) => state.auth?.userData?.accessToken);
    const clientCode = useSelector((state) => state.auth?.userData?.user?.clientCode);
    const [test, setTest] = useState(null);
    const [trigger, setTrigger] = useState(false);
    const [attachOpen, setAttachOpen] = useState(false);

    useEffect(() => {
        const getAttachedTest = async () => {
            try {
                const res = await getTestByJdId(jdId, accessToken, clientCode);
                if (res) {
                    setTest(res);
                    setIsTestAttached(true);
                    setAttachedTestData(res);
                }
            } catch (error) {
                const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again."
                setTest(null);
                setIsTestAttached(false);
                setAttachedTestData(null);
                toast.warning(errMsg);
            }
        }
        getAttachedTest();
    }, [trigger])

    const handleDetach = async (id) => {
        try {
            const res = await attachJobInfo(jdId, id, true, accessToken, clientCode);
            if (res) {
                toast.success(`Test Successfully detached with JdId ${jdId}`, 5000);
                setTrigger(prev => !prev);
            }
        } catch (error) {
            const errMsg = error?.response?.data?.notify?.message || "An error occurred. Please try again."
            toast.error(errMsg);
        }
    }

    const handleAttachOpen = () => {
        setAttachOpen(true);
    }

    return (
        <MainBox>
            <ModalHOC
                setOpenNewInterviewModal={setAttachOpen}
                openNewInterviewModal={attachOpen}
                component={
                    <AttachTestForm
                        jdId={jdId}
                        handleClose={() => {
                            setAttachOpen(false);
                            setTrigger(prev => !prev);
                        }}
                    />
                }
            />
            {
                test === null ?
                    <AddBox>
                        <span className='text'>No test attached to this JD, Please attach one to Proceed with Employer Test.</span>
                        <span className='threeDotBox' onClick={() => handleAttachOpen()}>
                            <img src={attachIcon} className="threeDotIcon" /> Attach Test
                        </span>
                    </AddBox>
                    :
                    <Box>
                        {title && <span className='title'>Attached Test</span>}
                        <div className='textMainBox'>
                            <div className='textBox'>
                                <span className='text'><span className='bold'>Test Type:</span> {test?.testType}</span>
                                <span className='text'><span className='bold'>Role:</span> {test?.role}</span>
                                <span className='text'><span className='bold'>Number of Questions:</span> {test?.testQuestions?.length}</span>
                            </div>
                            <div className='textBox'>
                                <span className='text'><span className='bold'>Test Name:</span> {test?.name}</span>
                            </div>
                        </div>

                        <span onClick={() => handleDetach(test?.id)} className='threeDotBox'>
                            <img src={attachIcon} className="threeDotIcon" /> Detach Test
                        </span>
                    </Box>
            }
        </MainBox>
    )
}

export default AttachTestBox


const MainBox = styled.div`
display: flex;
flex-direction: column;
width: 100%;
border: 0.08rem solid #C4C4C4;
border-radius: 0.3rem;
box-sizing: border-box;

`

const Box = styled.div`
display: flex;
flex-direction: row;
width: 100%;
position: relative;
padding: 1rem 1rem;
box-sizing: border-box;
align-items: center;
justify-content: space-between;

.textMainBox {
    display: flex;
flex-direction: column;
width: 100%;
gap: 0.5rem;
}

.textBox {
    font-size: 0.9rem; 
    font-weight: 400;
    font-family: var(--font);
    display: flex;
}

.text {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.bold {
    font-weight: 500;
}

.title {
    font-size: 0.9rem;
    font-weight: 600;
    position: absolute;
    top: -10rem;
    background-color: var(--white);
    padding: 0 0.3rem;
  }

  .threeDotBox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: var(--white);
    padding: 0.5rem 0.8rem;
    border: 0.075rem solid var(--lightOrange);
    border-radius: 0.5rem;
    cursor: pointer;
    white-space: nowrap;
    font-size: 0.9rem;
    font-weight: 600;
}

.threeDotIcon {
    width: 0.6rem;
    height: 0.6rem;
    cursor: pointer;
    border: 0.08rem solid grey;
    padding: 0.15rem;
    border-radius: 0.2rem;
  }

`

const AddBox = styled.div`
display: flex;
flex-direction: row;
width: 100%;
position: relative;
padding: 1rem 1rem;
box-sizing: border-box;
align-items: center;
justify-content: space-between;

.text {
    font-size: 0.9rem;
    font-weight: 500;
}

.threeDotBox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: var(--white);
    padding: 0.5rem 0.8rem;
    border: 0.075rem solid var(--lightOrange);
    border-radius: 0.5rem;
    cursor: pointer;
    white-space: nowrap;
    font-size: 0.9rem;
    font-weight: 600;
}

.threeDotIcon {
    width: 0.6rem;
    height: 0.6rem;
    cursor: pointer;
    border: 0.08rem solid grey;
    padding: 0.15rem;
    border-radius: 0.2rem;
  }
`