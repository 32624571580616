import React, { useState } from 'react';
import styled from 'styled-components';
import { ChevronDown } from 'lucide-react';

const FAQContainer = styled.div`
  background-color: #fff;
  padding: 3rem 0;
`;

const FAQWrapper = styled.div`
  margin: 0 auto;
  padding: 0 16px;
    max-width: 72rem;

`;

const FAQHeader = styled.div`
  text-align: center;
  margin-bottom: 48px;
`;

const FAQTitle = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  background: linear-gradient(to right, #3b82f6, #9333ea);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const FAQSubtitle = styled.p`
  color: #9ca3af;
`;

const FAQList = styled.div`
  background-color: #fff;
  border-radius: 16px;
  padding: 0.5rem 1rem;
  backdrop-filter: blur(8px);
`;

const FAQItemContainer = styled.div`
  margin-top: 0.5rem;
`;

const FAQButton = styled.button`
  width: 100%;
  padding: 20px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  color:rgb(80, 81, 81);
  text-align: left;
  font-size: 1.125rem;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    border-radius: 8px;
  }
`;

const FAQAnswer = styled.div`
  overflow: hidden;
  max-height: ${({ isOpen }) => (isOpen ? '400px' : '0')};
  transition: max-height 0.2s;
  padding: ${({ isOpen }) => (isOpen ? '16px' : '0')};
  color: rgb(80, 81, 81);
  line-height: 1.5;
`;

const FAQFooter = styled.div`
  margin-top: 48px;
  text-align: center;
`;

const FooterText = styled.p`
  color: rgb(80, 81, 81);
  margin-bottom: 16px;
`;

const FooterButton = styled.button`
  background: linear-gradient(to right, #3b82f6, #9333ea);
  color: white;
  padding: 12px 32px;
  border-radius: 9999px;
  font-weight: 500;
  cursor: pointer;
  transition: transform 0.2s;
  border: none;

  &:hover {
    transform: scale(1.05);
  }
`;

const FAQItem = ({ question, answer, isOpen, onClick }) => {
  return (
    <FAQItemContainer>
      <FAQButton onClick={onClick}>
        <span>{question}</span>
        <ChevronDown
          className={`transition-transform duration-200 ${isOpen ? 'transform rotate-180' : ''}`}
        />
      </FAQButton>
      <FAQAnswer isOpen={isOpen}>{answer}</FAQAnswer>
    </FAQItemContainer>
  );
};

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(0);

  const faqs = [
    {
      question: "How does the AI-powered learning system work?",
      answer: "Our AI system analyzes your performance patterns, learning speed, and areas of difficulty to create a personalized learning path. It continuously adapts to your progress, automatically adjusting question difficulty, suggesting focused practice areas, and providing real-time feedback. The system uses advanced algorithms to identify knowledge gaps and recommends targeted study materials and practice questions."
    },
    {
      question: "What exams do you cover?",
      answer: "We cover a comprehensive range of examinations including: School Board Exams (CBSE, ICSE, State Boards), Engineering Entrance (JEE Main, JEE Advanced, BITSAT), Medical Entrance (NEET-UG, AIIMS), Government Exams (UPSC, SSC, Banking), Professional Certifications, and International Tests (IELTS, TOEFL, GRE, GMAT). Our platform is regularly updated with new exam patterns and syllabus changes."
    },
    {
      question: "Can I switch between different exam preparations?",
      answer: "Yes, absolutely! Our platform allows seamless switching between different exam preparations. You can maintain separate progress tracking and learning paths for each exam. This is particularly useful for students preparing for multiple exams simultaneously. Your progress and analytics are saved separately for each exam preparation track."
    },
    {
      question: "How accurate are the performance analytics?",
      answer: "Our performance analytics are highly accurate, based on comprehensive data analysis of your test performances, practice sessions, and learning patterns. The system uses advanced statistical models to track your progress, predict performance trends, and identify areas needing improvement. Regular calibration with actual exam results helps maintain prediction accuracy above 95%."
    },
    {
      question: "Do you offer offline access to study materials?",
      answer: "Yes, we provide offline access to study materials through our mobile app. You can download lessons, practice questions, and mock tests for offline use. Study materials are automatically synced when you're back online, ensuring your progress is always up to date. This feature is available for Pro and Premium plan subscribers."
    },
    {
      question: "What kind of support is available for students?",
      answer: "We offer multi-tiered support including: 24/7 doubt solving through AI chatbot, live doubt clearing sessions with subject experts, peer discussion forums, and dedicated mentor support (for Premium users). Technical support is available via email, chat, and phone. Premium users also get priority support with guaranteed response times."
    }
  ];


  return (
    <FAQContainer>
      <FAQWrapper>
        <FAQHeader>
          <FAQTitle>Frequently Asked Questions</FAQTitle>
          <FAQSubtitle>
            Get answers to common questions about our AI-powered learning platform
          </FAQSubtitle>
        </FAQHeader>

        <FAQList>
          {faqs.map((faq, index) => (
            <FAQItem
              key={index}
              question={faq.question}
              answer={faq.answer}
              isOpen={openIndex === index}
              onClick={() => setOpenIndex(openIndex === index ? -1 : index)}
            />
          ))}
        </FAQList>

        <FAQFooter>
          <FooterText>Still have questions? We're here to help!</FooterText>
          <FooterButton>Contact Support</FooterButton>
        </FAQFooter>
      </FAQWrapper>
    </FAQContainer>
  );
};

export default FAQSection;
