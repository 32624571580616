import React from 'react'
import { styled } from 'styled-components'
import Header from '../components/Header'
import HeroSection from '../components/HeroSection'
import FeatureSection from '../components/FeatureSection'
import { aiFeatures, caseStudies, features, howRegorTalentWork } from '../utils/allHomepageData.js'
import HowItWorkSection from '../components/HowItWorkSection.jsx'
import CaseStudy from '../components/CaseStudy.jsx'
import CTA from '../components/CTA.jsx'
import Footer from '../components/Footer.jsx'
import FAQSection from "../components/FAQSection.jsx"
import FeatureShowCase from "../components/AiFeatureShowCase.jsx"
import FeatureComparison from '../components/FeatureComparison.jsx'
import HeroSectionTop from '../components/HeroSectionTop.jsx'

const HomePage = () => {

    return (
        <StyledLanding>
            <StyledLandingTop>
                <Header />
                <HeroSectionTop />
                <HeroSection />
                <HowItWorkSection steps={howRegorTalentWork} />
                <FeatureSection features={features} />
                <FeatureShowCase aiFeatures={aiFeatures} />
                <FeatureComparison/>
                <CaseStudy studyData={caseStudies} />
                <FAQSection />
                <CTA />
            </StyledLandingTop>
            <Footer />
        </StyledLanding>
    )
}

export default HomePage

export const StyledLandingTop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    color: var(--color);
    background-color: var(--backgroundColor);
    gap: 3rem;

`
export const StyledLanding = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    color: var(--color);
    background-color: var(--backgroundColor);
`

