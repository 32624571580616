import styled from 'styled-components';

const Container = styled.div`
  background-color: #111827;
  padding: 3rem 0;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 0.25rem;
  background: linear-gradient(
    90deg, 
    #2662EB 24%, 
    #524FEA 30%,
rgb(198, 53, 234) 90%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-family: 'Inria Serif', serif;
`;

const Subtitle = styled.p`
  text-align: center;
  color:rgb(207, 212, 218);
  margin-bottom: 2rem;
  font-size: 1.2rem;
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 72rem;
  margin: 0 auto;
`;

const FeatureCard = styled.div`
  background-color:rgb(51, 61, 80);
  padding: 1.5rem;
  border-radius: 0.5rem;
`;

const FeatureTitle = styled.h3`
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    color:rgb(189, 195, 204); /* Tailwind's text-gray-400 */
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    .icon-wrapper {
      width: 1.5rem;
      height: 1.5rem;
      background: linear-gradient(
      90deg, 
    #524FEA 20%,
    rgb(198, 53, 234) 90%
      );
      border-radius: 9999px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        color: #ffffff;
        font-size: 0.75rem;
        font-weight: bold;
      }
    }
  }
`;

const AiExperience = () => {
  const features = [
    {
      title: 'Smart Question Generation',
      items: ['Dynamic difficulty adjustment', 'Pattern-based questions', 'Exam-specific formatting'],
    },
    {
      title: 'Predictive Analytics',
      items: ['Performance forecasting', 'Weakness identification', 'Success probability'],
    },
    {
      title: 'Learning Optimization',
      items: ['Adaptive study plans', 'Smart revision scheduling', 'Personalized pacing'],
    },
  ];

  return (
    <Container>
      <Title>
        AI-Powered Learning Experience
      </Title>
      <Subtitle>
        Experience the future of education with our advanced AI capabilities
      </Subtitle>
      <FeatureGrid>
        {features.map((feature, index) => (
          <FeatureCard key={index}>
            <FeatureTitle>{feature.title}</FeatureTitle>
            <FeatureList>
              {feature.items.map((item, idx) => (
                <li key={idx}>
                  <div className="icon-wrapper">
                    <span>✓</span>
                  </div>
                  {item}
                </li>
              ))}
            </FeatureList>
          </FeatureCard>
        ))}
      </FeatureGrid>
    </Container>
  );
};

export default AiExperience;
