import { Award } from 'lucide-react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: #fff;
  margin: 0 auto;
  width: 100%;
  padding: 1rem calc(50vw - 36rem);
  box-sizing: border-box;

`;

const Title = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(
    90deg, 
    #2662EB 24%, 
    #524FEA 30%,
rgb(198, 53, 234) 90%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-family: 'Inria Serif', serif;
  margin-bottom: 2rem; 
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr)); 
  gap: 2rem;
  

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const Card = styled.div`
  background-color: #fff; 
  padding: 1rem; 
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem; 
`;

const IconWrapper = styled.div`
  width: 2rem; 
  height: 2rem; 
  background: linear-gradient(90deg, #524FEA 20%, rgb(198, 53, 234) 90%);
  margin-right: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardHeaderText = styled.div`
display: flex;
flex-direction: column;
gap: 0.5rem;
`;

const Name = styled.span`
  font-size: 1.25rem; 
  font-weight: 600;
`;

const Exam = styled.span`
background: linear-gradient(
    90deg, 
    #2662EB 24%, 
    #524FEA 30%,
rgb(198, 53, 234) 90%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-family: 'Inria Serif', serif;
  font-weight: 600;
`;

const Quote = styled.p`
  color: #4b5563; 
  margin-bottom: 1rem; 
`;

const Score = styled.span`
  background: linear-gradient(
    90deg, 
    #2662EB 24%, 
    #524FEA 30%,
rgb(198, 53, 234) 90%
  ) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text;
  color: transparent;
  font-family: 'Inria Serif', serif;  
  font-weight: 600;
`;

const TestimonialsSection = () => {
    const testimonials = [
        {
            name: "Abhi",
            exam: "JEE",
            quote: "The AI-powered practice tests helped me analyze my weak areas and improve steadily. The adaptive learning features were key in achieving a strong rank in this highly competitive exam.",
            rank: "1402",
        },
        {
            name: "Ayush",
            exam: "State PSC",
            quote: "The personalized study plans and regular mock tests gave me the confidence to excel. The platform’s insights and exam-focused preparation strategies helped me secure a top rank.",
            rank: "77",
        },
        {
            name: "Riya",
            exam: "NEET",
            quote: "The platform’s AI-driven guidance and detailed analytics helped me focus on critical topics. The mock tests provided a realistic experience, helping me achieve an excellent rank.",
            rank: "782",
        },
        {
            name: "Karan",
            exam: "CAT",
            quote: "The performance tracking and time management tools were invaluable in my preparation. The adaptive recommendations allowed me to focus on areas needing improvement, helping me secure a remarkable rank.",
            rank: "112",
        },
    ];


    return (
        <Container>
            <Title>Success Stories</Title>
            <Grid>
                {testimonials?.map((testimonial, index) => (
                    <Card key={index}>
                        <CardHeader>
                            <IconWrapper>
                                <Award color="white" />
                            </IconWrapper>
                            <CardHeaderText>
                                <Name>{testimonial?.name}</Name>
                                <Exam>{testimonial?.exam}</Exam>
                            </CardHeaderText>
                        </CardHeader>
                        <Quote>"{testimonial?.quote}"</Quote>
                        <Score>Rank: {testimonial?.rank}</Score>
                    </Card>
                ))}
            </Grid>
        </Container>
    );
};

export default TestimonialsSection;
