import React from 'react';
import styled from 'styled-components';

const SectionWrapper = styled.div`
  background-color: #111827 !important;
  padding: 3rem 0;
`;

const SectionHeader = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
background: linear-gradient(
    90deg, 
    #2662EB 24%, 
    #524FEA 30%,
rgb(198, 53, 234) 90%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-family: 'Inria Serif', serif;
  margin-bottom: 0.5rem; /* mb-2 */
`;

const SectionSubText = styled.p`
  color: #a0aec0; 
  text-align: center;
  margin-bottom: 2rem; 
`;

const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 72rem; 
  margin: 0 auto;
  padding: 0 2rem;
`;

const GuaranteeText = styled.p`
  text-align: center;
  color: #a0aec0;
  margin-top: 1.5rem;
`;

const GuaranteeHighlight = styled.span`
  color: #22c55e; 
`;

// Pricing Card Styles
const CardWrapper = styled.div`
  background-color: #2d3748;
  border-radius: 0.5rem;
  padding: 1.5rem; /* p-6 */
  border: ${({ popular }) => (popular ? '2px solid #524fea' : 'none')};
`;

const PopularBadge = styled.div`
background: linear-gradient(
    90deg, 
    #2662EB 24%, 
    #524FEA 30%,
rgb(198, 53, 234) 90%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-family: 'Inria Serif', serif;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`;

const PlanTitle = styled.h3`
  color: #ffffff; 
  font-size: 1.25rem; 
  margin-bottom: 0.5rem;
`;

const PriceContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.25rem; 
  margin-bottom: 1.5rem; 
`;

const Price = styled.span`
  font-size: 1.5rem; /* text-2xl */
  color: #ffffff; /* text-white */
`;

const PriceUnit = styled.span`
  color: #a0aec0; /* text-gray-400 */
`;

const FeaturesList = styled.ul`
  margin-bottom: 1.5rem; /* mb-6 */
  list-style: none;
  padding: 0;
  gap: 0.75rem;
  display: flex;
  flex-direction: column;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  gap: 0.5rem; /* gap-2 */
  color: #a0aec0; /* text-gray-300 */
`;

const FeatureIcon = styled.div`
  width: 1rem; /* w-4 */
  height: 1rem; /* h-4 */
  border-radius: 50%;
  background-color: rgba(59, 130, 246, 0.125); /* bg-blue-500/20 */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FeatureCheck = styled.span`
background: linear-gradient(
    90deg, 
    #2662EB 24%, 
    #524FEA 30%,
rgb(198, 53, 234) 90%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-family: 'Inria Serif', serif;
  font-size: 0.75rem; /* text-xs */
`;

const ActionButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: ${({ popular }) => (popular ? '#524FEA' : '#524FEA')}; 
  color: white;
  &:hover {
    background-color: ${({ popular }) => (popular ? '#524FEA' : '#524FEA')}; 
  }
`;

// Pricing Card Component
const PricingCard = ({ plan, price, features, popular, buttonText }) => (
  <CardWrapper popular={popular}>
    {popular && <PopularBadge>Most Popular</PopularBadge>}
    <PlanTitle>{plan}</PlanTitle>
    <PriceContainer>
      <Price>₹{price}</Price>
      <PriceUnit>/month</PriceUnit>
    </PriceContainer>
    <FeaturesList>
      {features.map((feature, index) => (
        <FeatureItem key={index}>
          <FeatureIcon>
            <FeatureCheck>✓</FeatureCheck>
          </FeatureIcon>
          {feature}
        </FeatureItem>
      ))}
    </FeaturesList>
    <ActionButton popular={popular}>{buttonText}</ActionButton>
  </CardWrapper>
);

// Main Component
const PricingSection = () => (
  <SectionWrapper>
    <SectionHeader>Choose Your Success Path</SectionHeader>
    <SectionSubText>
      Flexible plans designed to support your exam preparation journey
    </SectionSubText>
    <PricingGrid>
      <PricingCard
        plan="Starter"
        price="999"
        features={["Basic Mock Tests", "Performance Analytics", "Study Materials"]}
        buttonText="Get Started"
      />
      <PricingCard
        plan="Pro"
        price="1,999"
        features={[
          "All Starter Features",
          "AI-Powered Mock Tests",
          "Personalized Learning Path",
          "Live Doubt Solving",
        ]}
        popular={true}
        buttonText="Get Pro Access"
      />
      <PricingCard
        plan="Premium"
        price="2,999"
        features={[
          "All Pro Features",
          "1-on-1 Mentoring",
          "Interview Preparation",
          "Priority Support",
        ]}
        buttonText="Get Premium"
      />
    </PricingGrid>
    <GuaranteeText>
      <GuaranteeHighlight>✓</GuaranteeHighlight> 30-day money-back guarantee
    </GuaranteeText>
  </SectionWrapper>
);

export default PricingSection;
