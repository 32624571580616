import React from 'react'
import MetricCard from './MetricCard';
import styled from 'styled-components';

const StyledCard = styled.div`
  padding: 1.5rem;
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  &:hover {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  }
`;

const CardHeader = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
margin-bottom: 1rem;
gap: 0.5rem;

`

const CardTitleText = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  color: #1f2937;
  padding: 0;
  margin: 0;
`;

const CardDescription = styled.p`
  color: #6b7280;
  margin-top: 0rem;

`;

const CardContent = styled.div`


`

const MetricCardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`;

const AiFeatureCard = ({ title, description, metrics }) => (
    <StyledCard>
        <CardHeader>
            <CardTitleText>{title}</CardTitleText>
            <CardDescription>{description}</CardDescription>
        </CardHeader>
        <CardContent>
            <MetricCardGrid>
                {metrics?.map((metric, index) => (
                    <MetricCard
                        key={index}
                        value={metric.value}
                        label={metric.label}
                        color={metric.color}
                    />
                ))}
            </MetricCardGrid>
        </CardContent>
    </StyledCard>
);

export default AiFeatureCard