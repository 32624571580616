import React from 'react'
import styled from 'styled-components';
import TestimonialsSection from './HomePage/TestimonialsSection';
import Footer from './HomePage/Footer';
import Favicon from "react-favicon";
import testFavicon from "../../assets/Academic/examPrep.ico"
import HeroSection from './HomePage/HeroSection';
import Features from './HomePage/Features';
import AiExperience from './HomePage/AiExperience';
import ExamCoverage from './HomePage/ExamCoverage';
import AnalyticsSection from './HomePage/AnalyticsSection';
import PricingSection from './HomePage/PricingSection';
import FAQSection from './HomePage/FAQSection'
import Header from './HomePage/Header';

const AcademicLandingPage = () => {
    return (
        <AppWrapper>
            <Favicon url={testFavicon} />
            <Header/>
            <HeroSection />
            <Features />
            <AiExperience />
            <ExamCoverage />
            <AnalyticsSection />
            <TestimonialsSection />
            <PricingSection/>
            <FAQSection/>
            <Footer />
        </AppWrapper>
    )
}

export default AcademicLandingPage


const AppWrapper = styled.div`
  font-family: var(--font);
  color: #333;

`;

