import { Lock, Settings, MessageCircleQuestion, HelpCircle, Shield, Users, Activity, LineChart, Camera, Phone, Laptop, Layers, Clock, BarChart, Bell, Globe, BarChart2, Eye, Filter, FileText } from 'lucide-react';

export const features = [
    {
        title: 'AI-Driven Candidate Matching',
        description: 'Leverage the power of artificial intelligence to instantly identify the best talent for your roles.',
        features: [
            'Smart Resume Parsing: Automatically analyze resumes and rank candidates based on job-fit.',
            'AI-Powered Matching: Match candidates to job descriptions using advanced algorithms.',
            'Eliminate Bias: Ensure fair and unbiased hiring with data-driven recommendations.'
        ],
    },
    {
        title: 'Seamless Collaboration & Workflow',
        description: 'Empower your HR team with tools that improve communication, transparency, and efficiency.',
        features: [
            'Team Collaboration: Easily share candidate profiles, feedback, and notes in real-time.',
            'Customizable Workflow: Tailor workflows to fit your hiring process, from application to offer.',
            'Role-Based Access: Securely control who sees what with role-specific permissions.',
        ],
    },
    {
        title: 'Automated Recruitment, Simplified',
        description: 'Automate tedious recruitment tasks to save time and improve productivity.',
        features: [
            'Resume Parsing & Storage: Automatically parse resumes and store them in secure cloud storage.',
            'Automated Communication: Schedule and send candidate emails, interviews, and reminders automatically.',
            'Job Board Integration: Post job openings across multiple platforms with a single click.',
        ],
    },
    {
        title: 'Scale Your Hiring Effortlessly',
        description: 'Adapt and grow with a solution that scales as your business grows.',
        features: [
            'Flexible for All Team Sizes: Whether you’re a startup or an enterprise, RegorTalent grows with you.',
            'Easy Integration: Seamlessly integrate with your existing HR tools, ATS, and other systems.',
            'Customizable Features: Tailor RegorTalent to meet your unique recruiting needs.',
        ],
    },
];

export const howRegorTalentWork = [
    {
        title: 'Upload Resumes and Job Descriptions',
        description:
            'Kick off your hiring process by simply uploading resumes and job descriptions to RegorTalent.',
        details: [
            'Resume Parsing: Automatically extracts key data such as skills, experience, and education.',
            'Job Description Matching: Provides an AI-based fit score for each candidate.',
        ],
    },
    {
        title: 'AI Matching & Ranking',
        description:
            'Intelligently match candidates with job requirements using AI.',
        details: [
            'Smart Candidate Scoring: Ranks candidates based on their fit.',
            'AI-Powered Recommendations: Top candidates to save time on screening.',
        ],
    },
    {
        title: 'Seamless Collaboration & Feedback',
        description:
            'Collaborate seamlessly with your hiring team.',
        details: [
            'Candidate Profiles: Share profiles, resumes, and feedback.',
            'Team Collaboration: Provide real-time feedback easily.',
        ],
    },
    {
        title: 'Automate Communication & Interviews',
        description:
            'Ensure smooth candidate communication.',
        details: [
            'Automated Emails: Send reminders and follow-ups.',
            'Interview Scheduling: Integrates with your calendar.',
        ],
    },
    {
        title: 'Real-Time Insights & Analytics',
        description:
            'Monitor the recruitment process with data insights.',
        details: [
            'Hiring Dashboards: Track metrics such as time-to-hire.',
            'Predictive Analytics: Forecast hiring needs.',
        ],
    },
    {
        title: 'Easy Integration with Your Existing Systems',
        description:
            'Fits seamlessly into your recruitment process.',
        details: [
            'ATS and CRM Integration.',
            'Scalable Solution: Adapts to hiring needs.',
        ],
    },
];

export const caseStudies = [
    {
        title: "FortuneTech Solutions",
        sections: [
            {
                heading: "Challenge",
                text: "Struggling to efficiently screen and hire top tech talent for their growing enterprise."
            },
            {
                heading: "Solution",
                text: "With RegorTalent’s AI-powered resume parsing and candidate scoring, FortuneTech streamlined their hiring process, reducing time-to-hire by 40%."
            },
            {
                heading: "Results",
                list: [
                    "Time-to-hire reduced from 30 days to 18 days",
                    "95% candidate-job fit accuracy",
                    "Improved collaboration between recruitment and hiring managers"
                ]
            }
        ]
    },
    {
        title: "Global Retail Hub",
        sections: [
            {
                heading: "Challenge",
                text: "High volume of applications overwhelmed the HR team, making it difficult to identify quality candidates quickly."
            },
            {
                heading: "Solution",
                text: "RegorTalent’s automated candidate ranking and collaborative dashboard enabled faster decision-making."
            },
            {
                heading: "Results",
                list: [
                    "Processed 10,000+ applications within 2 weeks",
                    "Increased interview-to-hire ratio by 60%",
                    "Significant reduction in manual screening time"
                ]
            }
        ]
    }
];

export const faqData = [
    {
        category: 'General Queries',
        icon: HelpCircle,
        questions: [
            {
                question: 'What is RegorTalent, and how does it work?',
                answer: 'RegorTalent is an AI-powered recruitment platform designed to streamline your hiring process. It uses advanced algorithms to parse resumes, match candidates to job roles, and provide data-driven insights to help you hire the best talent quickly and efficiently.'
            },
            {
                question: 'What industries and job roles does RegorTalent cater to?',
                answer: 'RegorTalent serves a wide range of industries, including IT, finance, healthcare, retail, and more. The platform is versatile and can be tailored to suit entry-level to executive-level hiring across various domains.'
            }
        ]
    },
    {
        category: 'Platform Features',
        icon: Settings,
        questions: [
            {
                question: 'How does RegorTalent improve the hiring process?',
                answer: 'Our platform automates time-consuming tasks like resume screening, candidate ranking, and interview scheduling. This allows your HR team to focus on engaging with top candidates, ultimately reducing time-to-hire and improving the quality of hires.'
            },
            {
                question: 'How accurate is the candidate-job matching feature?',
                answer: "RegorTalent's AI engine ensures a matching accuracy of up to 95%, based on the job description, candidate skills, and experience.Our system continually learns and improves, ensuring the best fit for your roles."
            },
            {
                question: 'Does RegorTalent offer candidate analytics and reporting?',
                answer: 'Yes, our platform provides in-depth analytics and reporting on candidate performance, hiring trends, and team productivity. These insights help HR teams make informed decisions and continuously improve their hiring strategies.'
            }
        ]
    },
    {
        category: 'Integration and Customization',
        icon: Settings,
        questions: [
            {
                question: 'Is RegorTalent compatible with my existing Applicant Tracking System (ATS)?',
                answer: 'Yes, RegorTalent seamlessly integrates with major ATS platforms. This ensures that you can continue using your current system while leveraging the advanced AI capabilities of RegorTalent.'
            },
            {
                question: "Can I customize the platform to meet my company's unique hiring needs?",
                answer: "Absolutely! RegorTalent offers customizable workflows, scoring parameters, and dashboards to align with your organization's hiring strategies and goals."
            }
        ]
    },
    {
        category: 'Data Security',
        icon: Lock,
        questions: [
            {
                question: 'Is my data secure on RegorTalent?',
                answer: 'We prioritize data security and comply with industry standards, including GDPR and other data protection regulations. Your data is encrypted and securely stored, ensuring confidentiality and integrity.'
            }
        ]
    },
    {
        category: 'Support and Onboarding',
        icon: MessageCircleQuestion,
        questions: [
            {
                question: 'What support options are available if I encounter issues?',
                answer: 'Our dedicated support team is available 24/7 to assist you. We also offer detailed documentation, onboarding sessions, and live chat support to ensure you get the most out of RegorTalent.'
            },
            {
                question: 'How can I get started with RegorTalent?',
                answer: "Getting started is easy! Sign up for a free trial or schedule a demo with our team. We'll guide you through the setup process and help you optimize your recruitment process right away."
            }
        ]
    }
];


export const aiFeatures = {
    "evaluationData": {
        technicalScore: 9.2,
        communicationMetrics: {
            problemSolving: 92,
            techKnowledge: 88,
            communication: 85,
            criticalThinking: 90,
            innovation: 87,
        },
    },

    "proctoringFeatures": [
        { icon: Users, text: 'Multi-person detection' },
        { icon: Shield, text: 'Browser activity monitoring' },
        { icon: Activity, text: 'AI-powered behavior analysis' },
        { icon: LineChart, text: 'Real-time anomaly detection' },
        { icon: Camera, text: 'Live proctoring via video and chat' },
        { icon: Laptop, text: 'Record and review proctoring' },
    ],

    "proctoringMetrics": [
        { value: "99.9%", label: "Detection Accuracy", color: "blue" },
        { value: "0.001%", label: "False positives", color: "blue" },
    ],

    "cards": [
        {
            title: "AI-Powered Test Creation",
            description: "Generates skill-based tests in minutes with 92% effectiveness.",
            metrics: [
                { value: "100+", label: "Test Templates", color: "orange" },
                { value: "500+", label: "Skills Covered", color: "orange" },
                { value: "92%", label: "Effectiveness", color: "orange" },
                { value: "5min", label: "Creation Time", color: "orange" },
            ],
        },
        {
            title: "AI Job Matching",
            description: "Matches candidates with 97% accuracy for better retention.",
            metrics: [
                { value: "97%", label: "Match Accuracy", color: "pink" },
                { value: "85%", label: "Time Saved", color: "pink" },
                { value: "2x", label: "Better Retention", color: "pink" },
                { value: "90%", label: "Hire Success", color: "pink" },
            ],
        },
    ],
}

export const featureComparisonMetrics = [
    {
        title: "Processing Scale",
        traditional: {
            value: "1",
            subtitle: "async interview",
        },
        ai: {
            value: "1,000",
            subtitle: "async interviews",
        },
        icon: Users,
    },
    {
        title: "Time Efficiency",
        traditional: {
            value: "600min",
            subtitle: "for 10 interviews",
        },
        ai: {
            value: "5min",
            subtitle: "for 10 interviews",
        },
        icon: Clock,
    },
    {
        title: "Resume-JD Matching Accuracy",
        traditional: {
            value: "65%",
            subtitle: "manual matching accuracy",
        },
        ai: {
            value: "95%",
            subtitle: "AI-driven matching accuracy",
        },
        icon: FileText,
    },
    {
        title: "Candidate Filtering",
        traditional: {
            value: "10",
            subtitle: "candidates per hour",
        },
        ai: {
            value: "1200",
            subtitle: "candidates per hour",
        },
        icon: Filter,
    },
];
