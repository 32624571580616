import React from 'react';
import styled from 'styled-components';


const GradientContainer = styled.div`
  padding: 1.5rem;
  border-radius: 1rem;
  background: white;
`;

const FeatureHeader = styled.div`
  margin-bottom: 0rem;

  h3 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #1a202c;
    margin-bottom: 0rem;
  }

  p {
    color: #718096;
    font-size: 0.9rem;
  }
`;

const FeatureList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0rem;
`;

const FeatureItem = styled.div`
  display: flex;
  align-items: flex-start;
  align-items: center;
  gap: 0.5rem;
  transition: transform 0.2s;
  font-size: 0.8rem;

  &:hover {
    transform: translateX(0.5rem);
  }
`;

const IconWrapper = styled.div`
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${({ index }) =>
    index % 2 === 0
      ? 'linear-gradient(to right, #60a5fa, #9f7aea)'
      : 'linear-gradient(to right, #0bc5ea, #60a5fa)'};
  transition: transform 0.2s;

  svg {
    width: 1rem;
    height: 1rem;
    color: white;
  }

  &:hover {
    transform: scale(1.1);
  }
`;


const FeaturesShowcaseContainer = styled.div`
  background: linear-gradient(
    90deg,
    rgba(132, 255, 253, 0.2) 0%,
    rgba(162, 158, 158, 0.2) 50%,
    rgba(132, 255, 253, 0.2) 100%
  );
  padding: 1rem 0rem;
  margin: 0rem auto;
  width: 95%;
  box-sizing: border-box;
  color: var(--color);
  border-radius: 0.75rem;

  @media (max-width: 768px) {
    padding: 4rem 1rem;
    margin: 0 1rem;
  }
`;

const FeaturesWrapper = styled.div`
  max-width: 2500px;
  margin: 0 auto;
  padding: 1rem 2rem;

  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));

  @media (max-width: 768px) {
    padding: 0 1rem;
    grid-template-columns: 1fr;
  }
`;


const FeatureCard = ({ title, description, features, index }) => (
      <GradientContainer index={index}>
        <FeatureHeader>
          <h3>{title}</h3>
          <p>{description}</p>
        </FeatureHeader>
        <FeatureList>
          {features?.map((feature, idx) => (
            <FeatureItem key={idx}>
              <IconWrapper index={index}>
                <svg
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.5"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M5 13l4 4L19 7" />
                </svg>
              </IconWrapper>
              <p>{feature}</p>
            </FeatureItem>
          ))}
        </FeatureList>
      </GradientContainer>
);

const FeatureSection = ({ features }) => {

  return (
    <FeaturesShowcaseContainer>
      <FeaturesWrapper>
        {features?.map((feature, index) => (
          <FeatureCard key={index} {...feature} index={index} />
        ))}
      </FeaturesWrapper>
    </FeaturesShowcaseContainer>
  );
};

export default FeatureSection;
