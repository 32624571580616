import React from 'react';
import styled from 'styled-components';

const TimelineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
  padding-top: 3rem;
`;

const ContentBlock = styled.div`
  display: none;
  width: 41.666%;

  @media (min-width: 768px) {
    display: block;
    padding: 1.5rem;
    border-radius: 0.75rem;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    &:hover {
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    }
  }

  &.left {
    margin-right: 0.5rem;
  }

  &.right {
    margin-left: 0.5rem;
  }
`;

const Title = styled.h3`
  font-size: 1.25rem;
  font-weight: bold;
  background: linear-gradient(to right, var(--lightOrange), #4f46e5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0.75rem;
`;

const Description = styled.p`
  color: #4b5563;
  margin-bottom: 1rem;
`;

const DetailsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    align-items: start;
    gap: 0.75rem;
    color: #4b5563;
  }

  .icon {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-top: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dbeafe;
    border-radius: 9999px;

    svg {
      width: 0.75rem;
      height: 0.75rem;
      color: var(--lightOrange);
    }
  }
`;

const TimelineCenter = styled.div`
  width: 16.666%;
  display: flex;
  justify-content: center;
  position: relative;

  &::before {
    content: '';
    width: 0.25rem;
    background: linear-gradient(to bottom, #bfdbfe, #bfdbfe, transparent);
    position: absolute;
    height: 100%;
  }

  .circle {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background: linear-gradient(to bottom right, var(--lightOrange), #4f46e5);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    position: sticky;
    top: 33%;

    span {
      color: white;
      font-weight: bold;
      font-size: 1.125rem;
    }
  }
`;

const MobileContent = styled.div`
  display: block;
  width: 100%;
  padding-left: 4rem;

  @media (min-width: 768px) {
    display: none;
  }

  padding: 1.5rem;
  border-radius: 0.75rem;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  &:hover {
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
`;

const TimelineItem = ({ step, index }) => (
  <TimelineContainer>
    {index % 2 === 0 && <ContentBlock className='left'>
      <>
        <Title>{step?.title}</Title>
        <Description>{step?.description}</Description>
        <DetailsList>
          {step?.details?.map((detail, i) => (
            <li key={i}>
              <span className="icon">
                <svg
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M5 13l4 4L19 7" />
                </svg>
              </span>
              <span>{detail}</span>
            </li>
          ))}
        </DetailsList>
      </>
    </ContentBlock>}

    <TimelineCenter>
      <div className="circle">
        <span>{index + 1}</span>
      </div>
    </TimelineCenter>

    {index % 2 === 1 && <ContentBlock className='right'>
      <>
        <Title>{step?.title}</Title>
        <Description>{step?.description}</Description>
        <DetailsList>
          {step?.details?.map((detail, i) => (
            <li key={i}>
              <span className="icon">
                <svg
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M5 13l4 4L19 7" />
                </svg>
              </span>
              <span>{detail}</span>
            </li>
          ))}
        </DetailsList>
      </>
    </ContentBlock>}

    <MobileContent>
      <Title>{step?.title}</Title>
      <Description>{step?.description}</Description>
      <DetailsList>
        {step?.details?.map((detail, i) => (
          <li key={i}>
            <span className="icon">
              <svg
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M5 13l4 4L19 7" />
              </svg>
            </span>
            <span>{detail}</span>
          </li>
        ))}
      </DetailsList>
    </MobileContent>
  </TimelineContainer>
);

const SectionContainer = styled.div`
  width: 95%;
  padding: 4rem 2.5%;
  margin: 0 2.5%;
  box-sizing: border-box;
  background: linear-gradient(
  90deg, 
  rgb(235, 247, 247) 37%,
  rgba(132, 255, 253, 1) 100%);
  border-radius: 1rem;
  border: 0.1rem solid rgba(132, 255, 253, 1);
`;

const SectionWrapper = styled.div`
  max-width: 70rem;
  margin: 0 auto;

  h1 {
    font-size: 2.25rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
    background: linear-gradient(to right, var(--lightOrange),rgb(48, 47, 48));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const HowItWorkSection = ({ steps }) => {

  return (
    <SectionContainer>
      <SectionWrapper>
        <h1>
          How{' '}
          <span>
            RegorTalent
          </span>{' '}
          Works
        </h1>

        <div>
          {steps?.map((step, index) => (
            <TimelineItem key={index} step={step} index={index} />
          ))}
        </div>
      </SectionWrapper>
    </SectionContainer>
  );
};

export default HowItWorkSection;
