import { Activity, BookOpen, TrendingUp, Zap } from 'lucide-react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 3rem 0;
  background-color: #fff;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 0.25rem;

  background: linear-gradient(
    90deg, 
    #2662EB 24%, 
    #524FEA 30%,
rgb(198, 53, 234) 90%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-family: 'Inria Serif', serif;
`;

const Subtitle = styled.p`
  text-align: center;
  color:rgb(26, 27, 28);
  margin-bottom: 2rem;
  font-size: 1.2rem;
`;

const CategoryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  max-width: 72rem;
  margin: 0 auto;
`;

const CategoryCard = styled.div`
  padding: 1.5rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
`;

const IconWrapper = styled.div`
  background: linear-gradient(90deg, #524FEA 20%, rgb(198, 53, 234) 90%);
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #ffffff;
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const CategoryTitle = styled.h3`
  font-weight: 600;
`;

const ExamList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    color: rgb(26, 27, 28); 
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    .dot {
      width: 0.5rem;
      height: 0.5rem;
      background: linear-gradient(90deg, #524FEA 20%, rgb(198, 53, 234) 90%);
      border-radius: 50%;
    }
  }
`;

const ExamCoverage = () => {
  const categories = [
    {
      title: 'School Boards',
      icon: BookOpen,
      exams: ['CBSE (Class 6-12)', 'ICSE/ISC', 'State Boards'],
    },
    {
      title: 'Engineering',
      icon: Zap,
      exams: ['JEE Main', 'JEE Advanced', 'BITSAT'],
    },
    {
      title: 'Medical',
      icon: Activity,
      exams: ['NEET-UG', 'AIIMS', 'JIPMER'],
    },
    {
      title: 'Government Exams',
      icon: BookOpen,
      exams: ['UPSC', 'SSC', 'Banking'],
    },
    {
      title: 'Scholarships',
      icon: TrendingUp,
      exams: ['NTSE', 'KVPY', 'Olympiads'],
    },
    {
      title: 'Languages & Skills',
      icon: BookOpen,
      exams: ['IELTS/TOEFL', 'Coding Skills', 'Soft Skills'],
    },
  ];

  return (
    <Container>
      <Title>
        Comprehensive Exam Coverage
      </Title>
      <Subtitle>
        Tailored preparation modules for every major examination category
      </Subtitle>
      <CategoryGrid>
        {categories.map((category, index) => (
          <CategoryCard key={index}>
            <Header>
              <IconWrapper>
                <category.icon />
              </IconWrapper>
              <CategoryTitle>{category.title}</CategoryTitle>
            </Header>
            <ExamList>
              {category.exams.map((exam, idx) => (
                <li key={idx}>
                  <div className="dot" />
                  {exam}
                </li>
              ))}
            </ExamList>
          </CategoryCard>
        ))}
      </CategoryGrid>
    </Container>
  );
};

export default ExamCoverage;
