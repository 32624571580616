import React from 'react'
import styled from 'styled-components';


const Card = styled.div`
  position: relative;
  padding: 1.5rem;
  background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.7));
  backdrop-filter: blur(0.5rem);
  border-radius: 1.25rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.25rem;
    background: linear-gradient(to right, transparent, #d1fae5 50%, transparent);
  }
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  .icon-container {
    padding: 0.5rem;
    border-radius: 50%;
    background: #d1fae5;
    .icon {
      width: 1.25rem;
      height: 1.25rem;
      color: #059669;
    }
  }
  h3 {
    font-size: 1.125rem;
    font-weight: 600;
    color: #374151;
  }
`;

const CardContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  .section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .value {
      font-size: 1.5rem;
      font-weight: bold;
    }
    .subtitle {
      font-size: 0.875rem;
    }
  }
  .traditional .value {
    color: #4b5563;
  }
  .ai .value {
    color: #059669;
  }
`;


const ComparisonMetric = ({ title, traditional, ai, icon: Icon }) => (
    <Card>
        <CardHeader>
            <div className="icon-container">
                <Icon className="icon" />
            </div>
            <h3>{title}</h3>
        </CardHeader>
        <CardContent>
            <div className="section traditional">
                <div className="value">{traditional.value}</div>
                <div className="subtitle">Traditional</div>
                <div className="subtitle">{traditional.subtitle}</div>
            </div>
            <div className="section ai">
                <div className="value">{ai.value}</div>
                <div className="subtitle">AI-Powered RegorTalent</div>
                <div className="subtitle">{ai.subtitle}</div>
            </div>
        </CardContent>
    </Card>
);

export default ComparisonMetric