import React from "react";
import styled from "styled-components";

const Navbar = styled.nav`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #1a1a2e;
  position: fixed;
  z-index: 2000;
  `;

const Brand = styled.div`
  background: linear-gradient(
    90deg, 
    #ffffff 24%, 
rgb(198, 53, 234) 90%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-family: 'Inria Serif', serif;
  font-weight: 700;
  font-size: 1.5rem; 
`;

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem; 
`;

const NavLink = styled.a`
  color: #d1d5db; 
  font-size: 1rem; 
  font-weight: 500;
  text-decoration: none;

  &:hover {
    color: white;
  }
`;

const GetStartedButton = styled.button`
background: linear-gradient(
    90deg, 
    #524FEA 20%,
rgb(198, 53, 234) 90%
  );
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 9999px; 
  font-size: 1rem; 
  font-weight: 600;
  border: none;
  cursor: pointer;

  &:hover {
background: linear-gradient(
    90deg, 
    #524FEA 20%,
rgb(198, 53, 234) 90%
  );  
}
`;

const Header = () => (
    <Navbar>
        <Brand>ExamPapers</Brand>
        <NavLinks>
            <NavLink href="#">Features</NavLink>
            <NavLink href="#">AI Tools</NavLink>
            <NavLink href="#">Exams</NavLink>
            <NavLink href="#">Analytics</NavLink>
            <NavLink href="#">Success Stories</NavLink>
            <NavLink href="#">Pricing</NavLink>
            <GetStartedButton>Get Started</GetStartedButton>
        </NavLinks>
    </Navbar>
);

export default Header;
