import React from 'react';
import styled from 'styled-components';
import { Phone, Mail, Facebook, Twitter, Instagram } from 'lucide-react';

const FooterContainer = styled.footer`
  background-color: #111111;
  color: white;
  padding: 4rem 0;
`;

const FooterContent = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 2rem;
`;

const FooterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
  margin-bottom: 3rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const Section = styled.div``;

const SectionTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
`;

const Text = styled.p`
  color: #9ca3af;
  margin-bottom: 1.5rem;
`;

const IconLink = styled.a`
  color: #9ca3af;
  transition: color 0.2s;

  &:hover {
    color: white;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 1rem;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  margin-bottom: 0.75rem;

  a {
    color: #9ca3af;
    text-decoration: none;
    transition: color 0.2s;

    &:hover {
      color: white;
    }
  }
`;

const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #9ca3af;
`;

const Copyright = styled.div`
  padding-top: 2rem;
  border-top: 1px solid #1f2937;
  text-align: center;
  color: #9ca3af;
`;

const Footer = () => {
    return (
        <FooterContainer>
            <FooterContent>
                <FooterGrid>
                    {/* Company Info */}
                    <Section>
                        <SectionTitle>AI Exam Prep</SectionTitle>
                        <Text>
                            Revolutionizing exam preparation with AI-powered personalized learning for students across India.
                        </Text>
                        <SocialIcons>
                            <IconLink href="#">
                                <Facebook size={20} />
                            </IconLink>
                            <IconLink href="#">
                                <Twitter size={20} />
                            </IconLink>
                            <IconLink href="#">
                                <Instagram size={20} />
                            </IconLink>
                        </SocialIcons>
                    </Section>

                    {/* Quick Links */}
                    <Section>
                        <SectionTitle>Quick Links</SectionTitle>
                        <List>
                            {["Home", "Features", "Pricing", "About Us", "Contact"].map((item) => (
                                <ListItem key={item}>
                                    <a href="#">{item}</a>
                                </ListItem>
                            ))}
                        </List>
                    </Section>

                    {/* Exam Categories */}
                    <Section>
                        <SectionTitle>Exam Categories</SectionTitle>
                        <List>
                            {["Class 6-8", "Class 9-10", "Class 11-12", "JEE Preparation", "NEET Preparation"].map(
                                (item) => (
                                    <ListItem key={item}>
                                        <a href="#">{item}</a>
                                    </ListItem>
                                )
                            )}
                        </List>
                    </Section>

                    {/* Contact Info */}
                    <Section>
                        <SectionTitle>Contact Info</SectionTitle>
                        <List>
                            <ListItem>
                                <ContactInfo>
                                    <Phone size={18} />
                                    <span>+91 1234567890</span>
                                </ContactInfo>
                            </ListItem>
                            <ListItem>
                                <ContactInfo>
                                    <Mail size={18} />
                                    <span>support@exampapers.ai</span>
                                </ContactInfo>
                            </ListItem>
                        </List>
                    </Section>
                </FooterGrid>

                <Copyright>
                    © 2025 Exam Papers. All rights reserved.
                </Copyright>
            </FooterContent>
        </FooterContainer>
    );
};

export default Footer;
