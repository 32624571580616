import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

// Styled Components
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  backdrop-filter: blur(10px);

`;

const ModalContent = styled.div`
  width: 75%;
  height: 90%;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border: 0.2rem solid white;

  @media (max-width: 768px) {
  height: 60%;
  }
  @media (max-width: 500px) {
  height: 50%;
  }
  @media (max-width: 400px) {
  height: 40%;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;

  &:hover {
    color: #ff0000;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  iframe {
    width: 100%;
    height: 101%;
  }
`;

// Modal Component
const VideoDialog = ({isOpen, setIsOpen}) => {
    const modalRef = useRef(null);

    const closeModal = () => setIsOpen(false);

    // Close modal when clicking outside the modal content
    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            closeModal();
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
            document.body.style.overflow = 'hidden'; // Prevent background scrolling
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            document.body.style.overflow = 'auto';
        }
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isOpen]);

    const [isMuted, setIsMuted] = useState(true);
    const videoId = 'gf1MSzwDk-A';

    useEffect(() => {
        // Unmute on mount
        setIsMuted(false);
    }, []);
    
    const getIframeSrc = () => {
        const mutedQueryParam = isMuted ? '' : '&mute=1';
        const suggestedQualityParam = '&vq=hd';
        const controlQuery = '&controls=1';
        const loopQuery = '&loop=1';
        return `https://www.youtube.com/embed/${videoId}?playlist=${videoId}&autoplay=1${mutedQueryParam}${suggestedQualityParam}${controlQuery}${loopQuery}`;
    };

    return (
        <>
            {isOpen && (
                <Overlay>
                    <ModalContent ref={modalRef}>
                        <CloseButton onClick={closeModal}>&times;</CloseButton>
                        <VideoContainer>
                            <iframe
                                src={getIframeSrc()}
                                title="YouTube video"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </VideoContainer>
                    </ModalContent>
                </Overlay>
            )}
        </>
    );
};

export default VideoDialog;
