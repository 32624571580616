import React from 'react';
import styled from 'styled-components';
import { BarChart, Bar, ResponsiveContainer } from 'recharts';
import { BarChart2, Target, Star } from 'lucide-react';

// Styled Components
const SectionWrapper = styled.div`
  background-color: #111827;
  padding: 3rem 2rem;
`;

const Header = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  background: linear-gradient(
    90deg, 
    #2662EB 24%, 
    #524FEA 30%,
rgb(198, 53, 234) 90%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-family: 'Inria Serif', serif;
  margin-bottom: 0.25rem;
`;

const SubText = styled.p`
  color: rgb(26, 27, 28);
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.2rem;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  max-width: 72rem;
  margin: 0 auto;
`;

const Card = styled.div`
  background-color: rgb(51, 61, 80);
  border-radius: 0.5rem;
  padding: 1.5rem;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

const CardTitle = styled.h3`
  color: #e2e8f0;
  font-size: 1.5rem;
  font-weight: 700;

`;

const CircularProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
`;

const CircularProgress = styled.div`
  position: relative;
  width: 8rem;
  height: 8rem;
`;

export const CircularBackground = styled.div`
  width: 100%;  
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(90deg, #524FEA 20%, rgb(198, 53, 234) 90%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;

  > * {
    position: relative;
    z-index: 2;
  }
`;

const CircularPercentage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  color: white;
`;

const ProgressBarWrapper = styled.div`
  flex: 1;
`;

const ProgressBarLabel = styled.span`
  display: block;
  color:rgb(220, 226, 235);
  margin-bottom: 0.5rem;
  font-size: 1rem;
`;

const ProgressBar = styled.div`
  width: 100%;
  background-color: #4a5568;
  border-radius: 0.5rem;
  height: 0.5rem;
`;

const ProgressFill = styled.div`
  background: linear-gradient(90deg, #524FEA 20%, rgb(198, 53, 234) 90%);
  height: 100%;
  border-radius: 0.5rem;
  width: ${({ width }) => width || '0%'};
`;

const MetricsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`;

const MetricsCard = styled.div`
  background-color: rgba(74, 85, 104, 0.5);
  border-radius: 0.5rem;
  padding: 1rem;
`;

const FeatureList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const FeatureIconWrapper = styled.div`
  background: linear-gradient(90deg, #524FEA 20%, rgb(198, 53, 234) 90%);
  padding: 0.5rem;
  border-radius: 0.5rem;
`;

const FeatureTitle = styled.h4`
  color: white;
  font-weight: 500;
  margin-bottom: 0rem;
`;

const FeatureDescription = styled.p`
  color: #a0aec0;
  font-size: 0.875rem;
`;

// Component
const AnalyticsSection = () => {
    const weeklyData = [
        { week: 'W1', value: 30 },
        { week: 'W2', value: 40 },
        { week: 'W3', value: 55 },
        { week: 'W4', value: 75 },
    ];

    const features = [
        {
            icon: BarChart2,
            title: 'Performance Tracking',
            desc: 'Real-time monitoring of your progress across subjects',
        },
        {
            icon: Target,
            title: 'Improvement Insights',
            desc: 'AI powered suggestions for better performance',
        },
        {
            icon: Star,
            title: 'Personalized Goals',
            desc: 'Set and track custom learning objectives',
        },
    ];

    return (
        <SectionWrapper>
            <Header>Real-time Performance Analytics</Header>
            <SubText>
                Track your progress with advanced analytics and personalized insights
            </SubText>

            <GridContainer>
                <Card>
                    <CardHeader>
                        <CardTitle>Overall Performance</CardTitle>
                    </CardHeader>

                    <CircularProgressWrapper>
                        <CircularProgress>
                            <CircularBackground />
                            <CircularPercentage>75%</CircularPercentage>
                        </CircularProgress>
                        <ProgressBarWrapper>
                            <ProgressBarLabel>Mock Tests</ProgressBarLabel>
                            <ProgressBar>
                                <ProgressFill width="75%" />
                            </ProgressBar>
                        </ProgressBarWrapper>
                    </CircularProgressWrapper>

                    <MetricsGrid>
                        <MetricsCard>
                            <h4 style={{ color: '#a0aec0', marginBottom: '1rem', fontSize: '1.25rem' }}>
                                Weekly Progress
                            </h4>
                            <ResponsiveContainer width="100%" height={100}>
                                <BarChart data={weeklyData}>
                                    <Bar dataKey="value" fill="#524FEA" />
                                </BarChart>
                            </ResponsiveContainer>
                        </MetricsCard>

                        <MetricsCard>
                            <h4 style={{ color: '#a0aec0', marginBottom: '1rem', fontSize: "1.25rem" }}>
                                Subject Mastery
                            </h4>
                            <div style={{ color: '#e2e8f0', marginBottom: '0.5rem' }}>
                                Physics
                            </div>
                            <ProgressBar>
                                <ProgressFill width="85%" />
                            </ProgressBar>
                        </MetricsCard>
                    </MetricsGrid>
                </Card>

                <Card>
                    <h3 style={{ color: 'white', fontSize: '1.5rem', marginBottom: '1.5rem' }}>
                        Smart Analytics Features
                    </h3>
                    <FeatureList>
                        {features.map((feature, index) => (
                            <FeatureItem key={index}>
                                <FeatureIconWrapper>
                                    <feature.icon size={20} color="white" />
                                </FeatureIconWrapper>
                                <div>
                                    <FeatureTitle>{feature.title}</FeatureTitle>
                                    <FeatureDescription>{feature.desc}</FeatureDescription>
                                </div>
                            </FeatureItem>
                        ))}
                    </FeatureList>
                </Card>
            </GridContainer>
        </SectionWrapper>
    );
};

export default AnalyticsSection;
