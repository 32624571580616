import React from "react";
import styled from "styled-components";

const CardContainer = styled.div`
  background-color: ${({ color }) => getBackgroundColor(color)};
  border-radius: 0.5rem;
  padding: 1rem;
  text-align: center;
`;

const Value = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${({ color }) => getTextColor(color)};
`;

const Label = styled.div`
  font-size: 0.875rem;
  color: ${({ color }) => getSecondaryTextColor(color)};
`;

const getBackgroundColor = (color) => {
    const colors = {
        emerald: "#D1FAE5",
        blue: "#DBEAFE",
        red: "#FEE2E2",
        orange: "#FFEDD5",
        pink: "#FCE7F3",
        purple: "#EDE9FE",
        teal: "#CCFBF1",
        cyan: "#ECFEFF",
    };
    return colors[color] || colors.emerald;
};

const getTextColor = (color) => {
    const colors = {
        emerald: "#047857",
        blue: "#1E3A8A",
        red: "#991B1B",
        orange: "#C2410C",
        pink: "#9D174D",
        purple: "#5B21B6",
        teal: "#0D9488",
        cyan: "#0891B2",
    };
    return colors[color] || colors.emerald;
};

const getSecondaryTextColor = (color) => {
    const colors = {
        emerald: "#065F46",
        blue: "#1E40AF",
        red: "#B91C1C",
        orange: "#9A3412",
        pink: "#831843",
        purple: "#4C1D95",
        teal: "#115E59",
        cyan: "#155E75",
    };
    return colors[color] || colors.emerald;
};


const MetricCard = ({ value, label, color = "emerald" }) => (
    <CardContainer color={color}>
        <Value color={color}>{value}</Value>
        <Label color={color}>{label}</Label>
    </CardContainer>
);

export default MetricCard;
