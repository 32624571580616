import React from 'react';
import styled from 'styled-components';
import { featureComparisonMetrics } from '../utils/allHomepageData';
import ComparisonMetric from './ComparisonMetric';

const Container = styled.div`
  position: relative;
  width: 95%;
  margin: 0 auto;
  padding-left: 5rem;
  padding-right: 5rem;
  box-sizing: border-box;
  &::before,
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 1.5rem;
    z-index: -10;
  }
  &::before {
    background: linear-gradient(to bottom right, rgba(42, 252, 241, 0.5), white, rgba(240, 255, 240, 0.3));
  }
  &::after {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(1rem);
  }

  @media(max-width: 600px){
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  }
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 3rem;
  h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #1f2937;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1.125rem;
    color: #4b5563;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 1.5rem;
  margin-bottom: 1rem;

  @media(max-width: 600px){
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  @media(max-width: 400px){
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

const DecorativeElement = styled.div`
  position: absolute;
  width: 16rem;
  height: 16rem;
  background: rgba(255, 0, 0, 1);
  border-radius: 50%;
  filter: blur(3rem);
  z-index: -20;
  &.top {
    top: 25%;
    left: 0;
  }
  &.bottom {
    bottom: 25%;
    right: 0;
  }
`;


const FeatureComparison = () => {

    return (
        <Container>
            <Header>
                <h1>Optimize Your Company's Hiring</h1>
                <p>Transform your recruitment process with AI-powered solutions</p>
            </Header>
            <Grid>
                {featureComparisonMetrics?.map((metric, index) => (
                    <ComparisonMetric key={index} {...metric} />
                ))}
            </Grid>
            <DecorativeElement className="top" />
            <DecorativeElement className="bottom" />
        </Container>
    );
};

export default FeatureComparison;
