import React, { useEffect } from "react"
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Signup from "./pages/Signup"
import Login from "./pages/Login"
import Scorecard from "./pages/Scorecard"
import ProfilePage from "./pages/ProfilePage"
import SettingPage from "./pages/SettingPage"
import Activate from "./pages/Activate"
import About from "./pages/menuPages/About"
import Products from "./pages/menuPages/Products"
import Service from "./pages/menuPages/Service"
import ResearchPaper from "./pages/menuPages/ResearchPaper"
import CaseStudies from './pages/menuPages/CaseStudies'
import Support from './pages/menuPages/Support'
import Privacy from './pages/menuPages/Privacy'
import Terms from './pages/menuPages/Terms'
import Disclaimer from './pages/menuPages/Disclaimer'
import ScrollToTop from "./components/commonComponents/ScrollToTop"
import Reset from "./pages/Reset"
import Contact from "./pages/menuPages/Contact"
import JobDescriptions from "./pages/JobDescriptions"
import JdDetails from "./pages/JdDetails"
import Home from "./pages/Home"
import Demo from "./pages/Demo"
import Forgot from "./pages/Forgot"
import Talentview from "./pages/submenuPages/Talentview"
import TalentBoard from "./pages/submenuPages/TalentBoard"
import TalentSource from "./pages/submenuPages/TalentSource"
import Screening from "./pages/submenuPages/Screening"
import DataAnalytics from "./pages/submenuPages/DataAnalytics"
import TalentManagement from "./pages/submenuPages/TalentManagement"
import Enterprises from "./pages/submenuPages/Enterprises"
import RecruitmentAgency from "./pages/submenuPages/RecruitmentAgency"
import JobSeeker from "./pages/submenuPages/JobSeeker"
import JobSeekerDashboard from "./pages/JobSeekerDashboard"
import EmployerDashboard from "./pages/EmployerDashboard"
import ScheduleInterview from "./components/Interviews/EmployerDashboard/Schedule/ScheduleInterview"
import Career from "./pages/menuPages/Career"
import Sales from "./pages/menuPages/Sales"
import EmployerInterviewsStatus from "./components/Interviews/EmployerDashboard/sidebarPages/EmployerInterviewTabs"
import QuestionComponent from "./pages/ProctoredInterview"
import Proctored from "./pages/ProctoredInterview"
import SlotSelection from "./components/Interviews/SeekerDashboard/SlotSelection"
import CreateResumePage from "./pages/CreateResumePage"
import Invite from "./components/Interviews/EmployerDashboard/Schedule/Invite"
import InviteSuccess from "./components/Interviews/EmployerDashboard/Schedule/InviteSuccess"
import CreateInterview from "./pages/CreateInterview"
import PricingPlan from "./pages/menuPages/PricingPlan"
import AccessDenied from "./pages/AccessDenied"
import ProtectedRoute from "./components/commonComponents/ProtectedRoute"
import { AuthenticationConstants } from "./utils/constants"
import EmployerFeedback from "./components/Interviews/EmployerDashboard/EmployerFeedback"
import MatchedResumeTabs from "./components/Interviews/EmployerDashboard/Schedule/MatchedResumeTabs"
import JdListingTabs from "./pages/agency/JdListingTabs"
import CandidateListingTabs from "./pages/agency/CandidateListingTabs"
import ParameterSelector from "./components/Interviews/CreateInterview/ParamaterSelector"
import BulkEmailInvite from "./components/Interviews/EmployerDashboard/Schedule/BulkEmailInvite"
import BulkInviteSuccess from "./components/Interviews/EmployerDashboard/Schedule/BulkInviteSuccess"
import FeedbackPage from "./pages/FeedbackPage"
import ScreenCaptureComponent from "./pages/ScreenCaptureComponent"
import AcademicDashboard from "./components/Academic/AcademicDashboard"
import PracticeTest from "./components/Academic/SidebarPages/PracticeTest"
import JiraHelpCenterLandingPage from "./components/commonComponents/JiraHelpCenter/JiraHelpCenterLandingPage"
import JiraRequestTypesSelectionPage from "./components/commonComponents/JiraHelpCenter/JiraRequestTypesSelectionPage"
import JiraCreateIssuePage from "./components/commonComponents/JiraHelpCenter/JiraCreateIssuePage"
import AllJiraIssueRequests from "./components/commonComponents/JiraHelpCenter/AllJiraIssueRequests"
import JiraIssueDetails from "./components/commonComponents/JiraHelpCenter/JiraIssueDetails"
import AcademicLogin from "./components/Academic/AcademicLogin"
import AcademicSignup from "./components/Academic/AcademicSignup"
import PublicJobListing from "./pages/PublicJobListing"
import CreateTest from "./components/Academic/SidebarPages/CreateTest"
import OngoingTest from "./components/Academic/SidebarPages/OngoingTest"
import TestScorePage from "./components/Academic/SidebarPages/TestScorePage"
import AcademicPrivacy from "./components/Academic/MenuPages/AcademicPrivacy"
import AcademicTerms from "./components/Academic/MenuPages/AcademicTerms"
import AcademicDisclaimer from "./components/Academic/MenuPages/AcademicDisclaimer"
import InterviewCompletedMessage from "./components/Interviews/SeekerDashboard/seekerCommonComponents/InterviewCompletedMessage"
import JDTagsInstructionMessage from "./components/Interviews/EmployerDashboard/commonComponents/JDTagsInstructionMessage"
import ScheduleInterviewBulk from "./components/Interviews/EmployerDashboard/Schedule/ScheduleInterviewBulk"
import EmployerDashboardTrial from "./pages/EmployerDashboardTrial"

import OnboardUser from "./features/Admin/userOnboard/pages/OnboardUser"
import { GoogleOAuthProvider } from "@react-oauth/google"
import HomePage from "./features/HomePage/pages/HomePage"
import AcademicLandingPage from "./components/Academic/AcademicLandingPage"

const App = () => {

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/login" element={
            <GoogleOAuthProvider clientId='272740048280-q8qoi2ee125kctu2kcu9tkos04ds4q77.apps.googleusercontent.com' >
              <Login />
            </GoogleOAuthProvider>
          } />
          <Route path="/signup" element={<Signup />} />
          <Route path="/activate" element={<Activate />} />
          <Route path="/pwdreset/:id" element={<Reset />} />
          <Route path="/reset/:id" element={<Forgot />} />

          <Route element={<ProtectedRoute role={"basic"} />}>
            <Route path="/score/:interviewId" element={<Scorecard />} />

            // Atlassian Support Routes
            <Route path='/servicedesk/customer/portal/:portalId' element={<JiraHelpCenterLandingPage />} />
            <Route path='/servicedesk/customer/portal/:portalId/group/:groupId' element={<JiraRequestTypesSelectionPage />} />
            <Route path='/servicedesk/customer/portal/:portalId/group/:groupId/create/:requestId' element={<JiraCreateIssuePage />} />
            <Route path='/servicedesk/customer/user/requests/:portalId' element={<AllJiraIssueRequests />} />
            <Route path='/servicedesk/customer/portal/:portalId/:issueKey' element={<JiraIssueDetails />} />


            <Route element={<ProtectedRoute role={AuthenticationConstants.jobseeker} />}>
              <Route path="/dashboard/jobseeker" element={<JobSeekerDashboard />} />
              <Route path="/proctor-test" element={<QuestionComponent />} />
              <Route path="/slot-selection/:token" element={<SlotSelection />} />
              <Route path='/create/:skillsEndpoint' element={<ParameterSelector />} />
              <Route path="/create-interview/:interviewId/:interviewState" element={<CreateInterview />} />
              <Route path="/ongoing-interview/:interviewId/:interviewState" element={<Proctored />} />
              <Route path="/interview/completedMessage" element={<InterviewCompletedMessage />} />

            </Route>

            <Route element={<ProtectedRoute role={AuthenticationConstants.employer} />}>
              <Route path="/dashboard/employer" element={<EmployerDashboard />} />
              <Route path="/dashboard/agency" element={<EmployerDashboard />} />
              <Route path="/schedule" element={<ScheduleInterview />} />
              <Route path="/schedule/bulk" element={<ScheduleInterviewBulk />} />
              <Route path="/schedule/matches/:jdId" element={<MatchedResumeTabs />} />
              <Route path="/schedule/invite/:jdId" element={<Invite />} />
              <Route path="/schedule/invite/success" element={<InviteSuccess />} />
              <Route path="/schedule/bulk/success" element={<BulkInviteSuccess />} />
              <Route path="/employer/interview-status/:jdId" element={<EmployerInterviewsStatus />} />
              <Route path="/schedule/bulk/:jdId" element={<BulkEmailInvite />} />
            </Route>

            {/* Academic Routes */}
            <Route element={<ProtectedRoute role={AuthenticationConstants.academic} />}>
              <Route path="/dashboard/academic" element={<AcademicDashboard />} />
              <Route path="/academic/practice-test/:courseCategory/:course" element={<PracticeTest />} />
              <Route path="/academic/create-test/:testId/:testState" element={<CreateTest />} />
              <Route path="/academic/ongoing-test/:testId/:testState" element={<OngoingTest />} />
              <Route path="/academic/score/:testId" element={<TestScorePage />} />
            </Route>
            {/* Academic Routes */}

          </Route>

          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/setting" element={<SettingPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/products" element={<Products />} />
          <Route path="/services" element={<Service />} />
          <Route path="/research-paper" element={<ResearchPaper />} />
          <Route path="/case-studies" element={<CaseStudies />} />
          <Route path="/support" element={<Support />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/career" element={<Career />} />
          <Route path="/sales" element={<Sales />} />
          <Route path="/pricing" element={<PricingPlan />} />

          <Route path="/demo" element={<Demo />} />

          {/* Submenu Pages  */}
          <Route path="/product/talentview" element={<Talentview />} />
          <Route path="/product/talentsource" element={<TalentSource />} />
          <Route path="/product/talentboard" element={<TalentBoard />} />
          <Route path="/service/screening" element={<Screening />} />
          <Route path="/service/data-analytics" element={<DataAnalytics />} />
          <Route path="/service/talent-management" element={<TalentManagement />} />
          <Route path="/solution/enterprise" element={<Enterprises />} />
          <Route path="/solution/recruitment-agency" element={<RecruitmentAgency />} />
          <Route path="/solution/job-seeker" element={<JobSeeker />} />
          <Route path='/create-resume/:resumeId' element={<CreateResumePage />} />
          <Route path='/access-denied' element={<AccessDenied />} />
          <Route path='/JdListing' element={<JdListingTabs />} />
          <Route path='/candidateListing/:empClientCode/:jdId' element={<CandidateListingTabs />} />
          <Route path="/interviewer-feedbacks/:token" element={<EmployerFeedback />} />
          <Route path="/feedback" element={<FeedbackPage />} />
          <Route path="/recording" element={<ScreenCaptureComponent />} />


          {/* Academic Public Routes */}
          <Route path="/academic" element={<AcademicLandingPage />} />
          <Route path="/academic/login" element={
            <GoogleOAuthProvider clientId='737855689405-n95poi9trnijkf93gua91fltr70cvfpo.apps.googleusercontent.com' >
              <AcademicLogin />
            </GoogleOAuthProvider>} />
          <Route path="/academic/signup" element={<AcademicSignup />} />

          {/* Menu Pages */}
          <Route path="/academic/privacy" element={<AcademicPrivacy />} />
          <Route path="/academic/terms" element={<AcademicTerms />} />
          <Route path="/academic/disclaimer" element={<AcademicDisclaimer />} />
          {/* Academic Public Routes */}


          {/* Public Routes */}
          <Route path="/job-listing/:jdId/:jobSummaryHash" element={<PublicJobListing />} />
          {/* Public Routes */}

          <Route path="jdMessage" element={<JDTagsInstructionMessage />} />
          <Route path="/dashboard/employer1" element={<EmployerDashboardTrial />} />

          <Route path="onboard" element={<OnboardUser />} />

          <Route path="/homepage" element={<HomePage/>} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  )
}


export default App;
