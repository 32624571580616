import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Button, TextField } from "@mui/material";
import dayjs from "dayjs";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { checkInterest } from "../../../../functions/api/employers/match/checkInterest";
import AttachTestBox from "../Schedule/AttachTestBox";

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 55%;
    width: 60%;
    height: 85%;
    margin-top: 5rem;
  }

  .btn {
    background-color: var(--lightOrange);
    margin-top: 1rem;
  }

  .btn:hover {
    background-color: var(--lightOrange);
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    margin: 1.5rem auto;
    padding-bottom: 10rem;

    .childBox1 {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        padding: 0rem;
        margin-bottom: 1rem;
        box-sizing: border-box;
      }

      .dateBox {
      display: flex;
      gap: 1rem;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      }

    .inputBox {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      border: 0.08rem solid #c4c4c4;
      padding: 1rem 1rem;
      border-radius: 0.3rem;
      position: relative;
      box-sizing: border-box;

      .title {
        font-size: 0.9rem;
        font-weight: 600;
        position: absolute;
        top: -0.8rem;
        background-color: var(--white);
        padding: 0 0.3rem;
      }
    }

    .childInputBox {
      display: flex;
      gap: 0.5rem;
      flex-flow: row wrap;

      .label {
        font-size: 0.9rem;
      }

      input {
        cursor: pointer;
      }

      span {
        align-items: center;
        font-size: 0.9rem;
        font-weight: 500;
        font-family: var(--font);
      }
    }
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CheckInterestSlotModal = ({ jdId, open, onClose, ids }) => {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [directSchedule, setDirectSchedule] = useState("");
    const [isTestAttached, setIsTestAttached] = useState(false);
    const [attachedTestData, setAttachedTestData] = useState(null);


    const accessToken = useSelector((state) => state.auth.userData?.accessToken);
    const clientCode = useSelector(
        (state) => state.auth.userData?.user?.clientCode
    );

    const formatDate = (date) => {
        return dayjs(date).format("YYYY-MM-DD");
    };

    const getDatesInRange = (start, end) => {
        const dates = [];
        let currentDate = dayjs(start);
        const endDate = dayjs(end);

        while (
            currentDate.isBefore(endDate) ||
            currentDate.isSame(endDate, "day")
        ) {
            dates.push(formatDate(currentDate));
            currentDate = currentDate.add(1, "day");
        }

        return dates;
    };

    const handleSubmit = async () => {
        const dateRange = getDatesInRange(fromDate, toDate);
        const formattedDateString = dateRange.join(",");

        if (directSchedule === "YES" && (!isTestAttached || attachedTestData === null)) {
            toast.warning("Please Attach Test to Proceed", 5000)
            return;
        }

        try {
            const res = await checkInterest(
                directSchedule === "YES" ? formattedDateString : "",
                ids,
                accessToken,
                clientCode
            );
            if (res) {
                toast.success("Interest Check sent successfully");
                setFromDate(null);
                setToDate(null);
                onClose();
            }
        } catch (error) {
            const errMsg =
                error?.response?.data?.notify?.message ||
                "An error occurred. Please try again.";
            toast.error(errMsg);
        }
    };

    const handleClose = (event, reason) => {
        if (reason !== "backdropClick") {
            onClose();
        }
    };

    return (
        <StyledDialog open={open} onClose={handleClose} disableEscapeKeyDown>
            <StyledDialogTitle>
                Select Slot Date Range for Interviews.
                <IconButton onClick={onClose} size="small">
                    <CloseIcon />
                </IconButton>
            </StyledDialogTitle>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className="box">
                        <div className="inputBox">
                            <span className="title">Direct Interview Schedule *</span>
                            <div className="childInputBox">
                                <label className="label">
                                    <input
                                        type="radio"
                                        value="YES"
                                        checked={directSchedule === "YES"}
                                        onChange={() => setDirectSchedule("YES")}
                                    />
                                    <span>Yes</span>
                                </label>
                                <label className="label">
                                    <input
                                        type="radio"
                                        value="NO"
                                        checked={directSchedule === "NO"}
                                        onChange={() => setDirectSchedule("NO")}
                                    />
                                    <span>NO</span>
                                </label>
                            </div>
                        </div>

                        {directSchedule === "YES" && <>

                            <div className="dateBox">
                                <DatePicker
                                    label="From Date"
                                    value={fromDate}
                                    onChange={(newValue) => setFromDate(newValue)}
                                    renderInput={(params) => <TextField {...params} required />}
                                />
                                -
                                <DatePicker
                                    label="To Date"
                                    value={toDate}
                                    onChange={(newValue) => setToDate(newValue)}
                                    renderInput={(params) => <TextField {...params} required />}
                                />
                            </div>
                            <div className="childBox1">
                                <AttachTestBox jdId={jdId} setAttachedTestData={setAttachedTestData} setIsTestAttached={setIsTestAttached} title={false} />
                            </div>
                        </>}
                        <Button
                            variant="contained"
                            className="btn"
                            onClick={handleSubmit}
                            disabled={(directSchedule === "YES" || directSchedule === "") && (!fromDate || !toDate)}
                        >
                            Submit Interest Check
                        </Button>
                    </div>
                </LocalizationProvider>
            </DialogContent>
        </StyledDialog>
    );
};

export default CheckInterestSlotModal;
